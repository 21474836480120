import  { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'





const getbannedImages = async () => {
    const response = await AuthAxios.get(`/image/banned`);
    const data = response.data.data;
    return data;
  };

  
  export const useGetBannedImages = () => {
    const {setBannedImages } = useContext(DashboardContext);
    return useQuery("fetch banned tribute ", () => getbannedImages(), {
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        // console.log(data, "banned data")
        setBannedImages(data);
      },
    });
  };
  
