import React, { useState } from 'react'
import "./Pricing.css"
import Nigeria from '../Assets/Nigeria.png'
import Australia from '../Assets/Australia.png'
import { FaChevronDown } from 'react-icons/fa'

function Countries({selectType, setType}) {
    const [showOptions, setshowOptions] = useState(false);

  return (
    <div className='country_select pr-10 pl-10   '>
    {
        selectType === "NGN" ?
            <div className="country_value" onClick={() => { setshowOptions(!showOptions);}}>
                <img src={Nigeria} alt="NGN" width='30px' />
                <span>NGN</span>
                <FaChevronDown />
            </div>
            :
            <div className="country_value" onClick={() => { setshowOptions(!showOptions);}}>
                <img src={Australia} alt="AUD" width='30px' />
                <span>AUD</span>
                <FaChevronDown />
            </div>
    }
    {
        showOptions ?
            <div className="country_options ">
                <div className="country_opt" onClick={() => { setshowOptions(!showOptions); setType('NGN') }}>
                    <img src={Nigeria} alt="NGN" width='30px' />
                    <span>Nigerian Naira (NGN)</span>
                </div>
                <div className="country_opt" onClick={() => { setshowOptions(!showOptions); setType('AUD') }}>
                    <img src={Australia} alt="AUD" width='30px' />
                    <span>Australian Dollar (AUD)</span>
                </div>
            </div>
            :
            null

    }

</div>
  )
}

export default Countries