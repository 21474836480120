import React, { forwardRef, useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import calender from "../../Assets/calendar.svg";
import moreicon from "../../Assets/moreicon.svg";

import useTransaction from "../../api/useTransaction";
import { useTransdelete } from "../../api/useTransdelete";
import DatePicker from "react-datepicker";
import { TbLineDotted } from "react-icons/tb";
import exporticon from "../../Assets/exporticon.svg";
import { jsPDF } from "jspdf";
import { useFilter } from "../../api/useFilter";
import moment from "moment";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { DashboardContext } from "../../Context/DashboardContext";
import { Navigate, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Responsive from "../../Components/Responsive";
import TransComponent from "./TransComponent";
import Dropdown from "../../Components/Dropdown";
import deletesvg from "../../Assets/deletesvg.svg";
import autoTable from 'jspdf-autotable';




function TestingPage() {
  const defaultMaterialTheme = createTheme();
  let navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const {
    UpdateMenuClicked,

    MoreInfodata,
    transdetails,
    infodata,
    filterdata, setfilterdata,
    settransindividual,
  } = useContext(DashboardContext);

  const { refetch, isLoading, data, isSuccess: success } = useTransaction();

  const {
    refetch: isrefetch,
    isSuccess,
    isLoading: Load,
    data: isdata,
  } = useFilter(moment(startDate)?.format("YYYY-MM-D"));

  const [setselectedclicked] = useState();

  const handleEditClick = (data, type) => {
    // console.log(data, type, "idddd");
  };



  useEffect(() => {
    isrefetch()

  }, [startDate]);


  const onSuccess = (item) => {

  };

  const onError = (error) => { };

  const { mutate: deleteUser, isSuccess: deletesuccess } = useTransdelete(onSuccess, onError);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };



  const columns = [
    { title: "Full Name", field: "fullname" },
    { title: "Email", field: "email" },
    { title: "Amount", field: "amount" },
    // { title: "Payment gateway", field: "payment_gateway" },
    // { title: "Payment reference", field: "payment_reference" },
    {
      title: "Payment Date",
      field: "payment_date",

      render: (rowData) => (
        <div>
          <p>{moment(rowData?.payment)?.format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    { title: "Status", field: "status" },
    {
      title: "More Details",
      field: "more",
      render: (rowData) => (
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            // marginLeft: "30px",
          }}
        >
          {/* <div></div>
          <div>
            {" "}
            <Dropdown
              editClick={() => handleEditClick(infodata, "Tribute")}
            // deleteClick={() => handleDeleteClick(trib_data.id, "Tribute")}
            // hideClick={() => handleHideClick(trib_data.id, "Tribute")}
            />
          </div> */}
          <button
            style={{
              background: '#FF7900', border: '1px solid #FF7900', padding: '3px', color: 'white'
            }}

          >View More</button>
        </div>
      ),
    },
  ];

  const doc = new jsPDF()

  const handleExport = () => {
    // const pdf = new jsPDF("p", "mm", "a4");

    // pdf.text(105, 10, "Transaction details", null, null, "center");
    // pdf.text(10, 20, "Manage transaction");

    // const generalInfocolumns = [
    //   "TableData",
    //   "id",
    //   "Name",
    //   "Email",
    //   "Amount",
    //   "Payment date",
    // ];

    // pdf.autoTable(
    //   generalInfocolumns,
    //   transdetails?.map((x, i) => {
    //     return [
    //       x?.tableData?.id,
    //       x?.id,
    //       x?.fullname,
    //       x?.email,
    //       x?.amount,
    //       x?.payment_date,
    //     ];
    //   }),
    //   {
    //     startY: 25,
    //   }
    // );

    // pdf.save("pdf");

    const pdf = new jsPDF("p", "mm", "a4");

    pdf.text(105, 10, "Transaction details", null, null, "center");
    pdf.text(10, 20, "Manage transaction");



    autoTable(doc, {
      head: [[
        // "TableData",
        "Full Name",
        "Email",
        "Amount",
        "Status",
        "Payment gateway",
        "Payment reference",
        "Payment Date"
      ]],
      body:

        data?.map((x, i) => {
          return [
            x?.fullname,
            x?.email,
            x?.amount,
            x?.status,
            x?.payment_gateway,
            x?.payment_reference,
            x?.payment_date,
          ];
        }),




    })

    doc.save('table.pdf')
  };


  const handleBulkDelete = (rowData) => {
    const id = rowData.map((x) => x?.id);

    const data = { transaction_ids: id };

    deleteUser(data);
    // console.log(data, "deleted data")
  };



  const HandleFilter = () => {
    return (
      // console.log(moment(startDate)?.format("YYYY-MM-D"), "startdfate")
      // console.log("startdfate", isdata),
      isrefetch()

      // console.log(transdetails, "to use"),
      // console.log(filterdata, "filtereddata")
      // isrefetch()
    );
  };


  useEffect(() => {
    if (deletesuccess || isSuccess) {
      refetch()
    }

  }, [refetch, deletesuccess]);






  return (
    <div>
      {isLoading || Load ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <body>
          <Navbar />
          {
            console.log(data, 'data')
          }
          <div>
            <div
              className="relative min-h-screen md:flex"
              data-dev-hint="container"
            >
              <input type="checkbox" id="menu-open" className="hidden" />

              <header
                className="bg-gray-600 text-gray-100 flex justify-between md:hidden"
                data-dev-hint="mobile menu bar"
              >
                <a
                  href="#/"
                  className="block p-4 text-white font-bold whitespace-nowrap truncate"
                >
                  {/* Create Tribute */}
                </a>

                <label
                  for="menu-open"
                  id="mobile-menu-button"
                  className="m-2 p-2 focus:outline-none hover:text-white hover:bg-gray-700 rounded-md"
                >
                  <svg
                    id="menu-open-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    id="menu-close-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </label>
              </header>

              <aside
                id="sidebar"
                className=" zindexproperty text-gray-100     space-y-6  px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
                data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation"
              >
                <Sidebar transclcik="trans" />
              </aside>

              <div className=" bg-black  bgclass "></div>

              <main
                id="content"
                className="flex-1 mt-8  xl:mr-12 md:w-12 sm:mr-0    bg-sidebar   "
              >
                <div className="mx-auto  ">
                  {isLoading || Load ? (
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 2,
                      }}
                      open={isLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    <div className="pt-7">
                      {/* <div></div> */}

                      <ThemeProvider theme={defaultMaterialTheme}>
                        <div>
                          <div className=" shadow-bxshadow-container   ">
                            <h1 className="font-inter pr-10 pl-10   not-italic pb-5 boldtitle  ">
                              Manage Transaction
                            </h1>
                          </div>

                          <div>
                            <div className="pr-10 pl-10 pt-8  ">
                              <h1 className="analytics">TRANSACTION</h1>
                            </div>
                          </div>

                          <div style={{ maxWidth: "100%" }}>
                            <div

                            >
                              <div className="pr-10 pl-10 pt-2 relative  ">
                                <div className="flex justify-between flexwidth ">
                                  <div className="flex justify-center flex-wrap toflexmobile  items-center gap-2 ">
                                    <div
                                      onClick={HandleFilter}
                                      className="dottedbody flex items-center cursor-pointer  "
                                    >
                                      <span>Filter</span>
                                    </div>

                                    <div
                                      style={{ position: "relative" }}
                                      className="flex items-center cursor-pointer "
                                    >
                                      <div>
                                        <DatePicker
                                          //   dateFormat="MMMM yyyy dd"
                                          //   showMonthYearDayPicker
                                          showDayMonthYearPicker
                                          selected={startDate}
                                          onChange={(date) => {
                                            return (setStartDate(date)

                                            );

                                            // date ? isrefetch(startDate) : console.log("fuck off"),
                                            // HandleFilter(date)
                                          }}
                                          className="date-picker"
                                        />
                                      </div>

                                      <div
                                        style={{
                                          position: "relative",
                                          right: "45px",
                                        }}
                                        className=""
                                      >
                                        <img src={calender} alt="calender" />
                                      </div>
                                    </div>

                                    <div className="dottedbody">
                                      <TbLineDotted size={32} color="#FF7900" />
                                    </div>

                                    <div
                                      onClick={handleExport}
                                      className="dottedbody flex items-center cursor-pointer  "
                                    >
                                      <div className=" pt-2  pb-2 pr-1">
                                        {" "}
                                        <img
                                          src={exporticon}
                                          alt="exporticon"
                                        />{" "}
                                      </div>
                                      <div>Export</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {isLoading ? (
                              <p>Loadingdata...</p>
                            ) : (
                              <div className="toptobottom ">
                                <MaterialTable
                                  icons={tableIcons}
                                  // data={transdetails?.map((v) => ({
                                  //   ...v,
                                  //   more: "...",
                                  // }))}
                                  data={data?.map((v) => ({
                                    ...v,
                                    more: "...",
                                  }))}
                                  // onSelectionChange={(rows) => setSelectedRows(rows)}
                                  // onSelectionChange={(rows) =>
                                  //   console.log(rows)
                                  // }
                                  columns={columns}
                                  options={{
                                    selection: true,
                                    showTitle: false,
                                    showEmptyDataSourceMessage: false,
                                    pageSizeOptions: [20, 50],
                                    paging: true,
                                    emptyRowsWhenPaging: false,
                                    pageSize: 20,

                                    showTextRowsSelected: false,
                                  }}
                                  actions={[
                                    {
                                      icon: () => (
                                        <img src={deletesvg} alt="deleteicon" />
                                      ),

                                      onClick: (event, rowData) => {
                                        handleBulkDelete(rowData);
                                      },
                                    },
                                  ]}
                                  localization={{
                                    pagination: {
                                      nextAriaLabel: "Next Page",
                                      labelRowsPerPage: " ",
                                    },
                                  }}
                                  onRowClick={(event, rowData, togglePanel) => {
                                    return (
                                      UpdateMenuClicked("moredetails"),
                                      MoreInfodata(rowData),
                                      // setselectedclicked(rowData?.map((x) => x?.id)),
                                      // console.log(rowData),
                                      navigate(`/moredetails/${rowData?.id}`),
                                      settransindividual(rowData)
                                    );
                                  }}
                                />
                              </div>


                            )}
                          </div>
                          {/* {
                            console.log(data?.map((v) => ({
                              ...v,
                              more: "...",
                            })), "data")
                          } */}
                        </div>
                      </ThemeProvider>
                    </div>
                  )}
                </div>
              </main>
            </div>
          </div>
        </body>
      )}
    </div>
  );
}

export default TestingPage;
