import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Line6 from "../Assets/Line6.svg";
import { TbLineDotted } from "react-icons/tb";
import calender from "../Assets/calendar.svg";
import CountrySelect from "../Components/CountrySelect";
import { DashboardContext } from "../Context/DashboardContext";
import Chart from "../Components/Chart";
import moment from "moment";
import { useGetPaymentData } from "../api/usePaymentHistory";

function Paymenthistory() {
  const [startDate, setStartDate] = useState(new Date());
  const [priceType, setpriceType] = useState("NGN");
  const [datepicked, setdatePicked] = useState(" ");

  const { paymentinfo, } = useContext(DashboardContext);




  const changingdate = moment(startDate)?.format("YYYY-MM-D")





const {refetch} = useGetPaymentData(
    priceType,
    moment(startDate).format("YYYY-MM-DD")
  );


  useEffect(() => {

    //  console.log(paymentinfo?.data?.data, "paymentinfooo")
  
    // console.log(paymentinfo?.data?.data, "paymentinfo")
    // setdatePicked(moment(startDate)?.format("YYYY-MM-D"));
    
     refetch()
    
    // console.log(paymentinfo?.data?.data, "paymentinfo")
    }, [priceType,  changingdate]);







  return (
    <div>
      <div className="pr-10 pl-10 pt-8 bordertop  ">
        <div className="flex gap-8">
          <div>
            <h1 className="analytics">PAYMENT HISTORY</h1>
          </div>

          <div>
            <button className="rounded-full text-[#FFFFFF] bg-[#FF7900] text-[11px] pr-1 pl-1 ">
              View Details{" "}
            </button>
          </div>
        </div>
      </div>

      <div className="pr-10 pl-10 pt-8">
        <div className="flex justify-between sm:flex-wrap  ">
          <div className="flex flex-wrap justify-center items-center gap-8 ">
            <div className=" ">
              <img src={Line6} alt="Line6" />
            </div>

            <div>
              {" "}
              <h1>Provisions Month</h1>{" "}
            </div>

            <div
              style={{ position: "relative" }}
              className="flex items-center "
            >
              <div>
                <DatePicker
                //   dateFormat="MMMM yyyy dd"
                //   showMonthYearDayPicker
                showDayMonthYearPicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="date-picker"
                />
              </div>

              <div
                style={{
                  position: "relative",
                  right: "45px",
                }}
                className=""
              >
                <img src={calender} alt="calender" />
              </div>
            </div>

            <div className="dottedbody sm:hidden  ">
              <TbLineDotted size={32} color="#FF7900" />
            </div>
          </div>

          <div>
            <div className="flex justify-center items-center  gap-8 sm:flex-wrap ">
              <div>
                <h1 className="font-inter text-[#11263C]  not-italic">
                  Total Revenue
                </h1>
              </div>

              <div className="amounthome">
                <h1 className="text-[#FFFFFF] ">
                  &#x20A6;
                  {paymentinfo?.data?.data?.total_revenue?.toLocaleString(
                    "en-US"
                  )}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <CountrySelect priceType={priceType} setpriceType={setpriceType} />
      </div>

      <div className="pr-10 pl-10 pt-8 pb-10 ">
        <Chart />
      </div>
    </div>
  );
}

export default Paymenthistory;
