import { useContext } from "react";
import { useQuery } from "react-query";
import { DashboardContext } from "../Context/DashboardContext";
import AuthAxios from "../utils/AuthAxios";

const getfilterdata = async (date) => {
  const response = await AuthAxios.get(`/transaction/filter/${date}`);
  const data = response;
  return data;
};

export const useFilter = (date, Success, error) => {
  const { setfilterdata, settransdetails  } = useContext(DashboardContext);

  return useQuery("fetch payment data", () => getfilterdata(date), {
     refetchOnWindowFocus: false,
    onSuccess: (data) => {
        // setfilterdata(data?.data?.data);
        // console.log(data?.data?.data, 'true');
          settransdetails(data?.data?.data)
    },

    onError: error
  
  });
};




