import React, { forwardRef, useContext } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useTransdelete } from "../../api/useTransdelete";
import moment from "moment";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { DashboardContext } from "../../Context/DashboardContext";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dropdown from "../../Components/DropdownAdmin";
import deletesvg from "../../Assets/deletesvg.svg";
import useAllAdmin from "../../api/useAllAdmin";
import useDeleteAdmin from "../../api/useDeleteAdmin";
import { useEffect } from "react";

function Alladmin() {
  const defaultMaterialTheme = createTheme();

  let navigate = useNavigate();

  const {
    UpdateMenuClicked,

    MoreInfodata,
    infodata,

    settransindividual,
  } = useContext(DashboardContext);

  const { refetch, isLoading, data } = useAllAdmin();

  const { refetch: refetchdelete, error: isErrordelete, isSuccess: adminsuccess } = useDeleteAdmin(
    infodata?.id
  );

  const onSuccess = (item, isErrordelete) => { };

  const handleEditClick = (data, type) => {
    navigate(`/singleadmin/${data?.id}`);
    // console.log(data);
  };

  const handleDeleteUser = (data, type) => {
    console.log(data);
    refetchdelete();
    // refetch();
  };

  const onError = (error) => {
    // console.log(error);
    // console.log(isErrordelete);
  };

  const { mutate: deleteUser, isSuccess } = useTransdelete(onSuccess, onError);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    { title: "ID", field: "id" },
    { title: "Full Name", field: "fullname" },
    { title: "Email", field: "email" },
    { title: "Role", field: "role" },
    { title: "Count", field: "email" },
    { title: "Phone", field: "phone" },
    {
      title: "Date Added",
      field: "created_on",

      render: (rowData) => (
        <div>
          <p>{moment(rowData?.date_added)?.format("DD/MM/YYYY")}</p>
        </div>
      ),
    },

    {
      title: "Status",
      field: "status",

      render: (rowData) => (
        <div>
          <p
            className={
              rowData.status === "active"
                ? "activestatus"
                : rowData.status === "expiring_soon"
                  ? "expiringsoonstatus"
                  : rowData.status === "expired"
                    ? "expiredstatus"
                    : "nonestatus"
            }
          >
            {rowData.status}
          </p>
        </div>
      ),
    },

    {
      title: "Action",
      field: "more",

      render: (rowData) => (
        <div>
          <Dropdown propone
            editClick={() => handleEditClick(infodata, "Tribute")}
            deleteClick={() => handleDeleteUser(infodata, "Tribute")}
            
          />
        </div>
      ),
    },
  ];

  const handleBulkDelete = (rowData) => {
    const id = rowData.map((x) => x?.id);

    const data = { transaction_ids: id };

    // deleteUser(data);
    // refetchdelete();
    console.log(data, 'data')
  };


  useEffect(() => {
    if (isSuccess || onSuccess || adminsuccess) {
      refetch()
    }
  }, [isSuccess, refetch, adminsuccess]);



  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <body>
          <Navbar />
          <div>
            <div
              className="relative min-h-screen md:flex"
              data-dev-hint="container"
            >
              <input type="checkbox" id="menu-open" className="hidden" />

              <header
                className="bg-gray-600 text-gray-100 flex justify-between md:hidden"
                data-dev-hint="mobile menu bar"
              >
                <a
                  href="#/"
                  className="block p-4 text-white font-bold whitespace-nowrap truncate"
                >
                  {/* Create Tribute */}
                </a>

                <label
                  for="menu-open"
                  id="mobile-menu-button"
                  className="m-2 p-2 focus:outline-none hover:text-white hover:bg-gray-700 rounded-md"
                >
                  <svg
                    id="menu-open-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    id="menu-close-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </label>
              </header>

              <aside
                id="sidebar"
                className=" zindexproperty text-gray-100     space-y-6  px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
                data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation"
              >
                <Sidebar alladmin="admin" />
              </aside>

              <div className=" bg-black  bgclass "></div>

              <main
                id="content"
                className="flex-1 mt-8  xl:mr-12 md:w-12 sm:mr-0    bg-sidebar   "
              >
                <div className="mx-auto">
                  {isLoading ? (
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 2,
                      }}
                      open={isLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    <div className="pt-7">
                     

                      <ThemeProvider theme={defaultMaterialTheme}>
                      <div className="shadow-bxshadow-container">
                          <div className="">
                            <h1 className="font-inter pr-2 not-italic pb-5 boldtitle">Manage Admins</h1>
                            <button style={{color:"white",backgroundColor:"orange", padding:"1%", marginLeft:"4%", borderRadius:"7%"}}
      
      onClick={() => navigate("/manageadmin")}
    >
     <p style={{fontSize:"12px"}}>Manage Admin</p> 
    </button>
                                <MaterialTable
                                
                                  icons={tableIcons}
                                  data={data?.map((v) => ({
                                    ...v,
                                    more: "...",
                                  }))}
                                  // onSelectionChange={(rows) =>
                                  //   console.log(rows)
                                  // }
                                  columns={columns}
                                  options={{
                                    selection: true,
                                    showTitle: false,
                                    showEmptyDataSourceMessage: false,
                                    pageSizeOptions: [20, 50],
                                    paging: true,
                                    emptyRowsWhenPaging: false,
                                    pageSize: 20,

                                    showTextRowsSelected: false,
                                    actionsColumnIndex: -1,
                                  }}
                                  actions={[
                                    {
                                      icon: () => (
                                        <img src={deletesvg} alt="deleteicon" />
                                      ),

                                      onClick: (event, rowData) => {
                                        handleBulkDelete(rowData);
                                        handleDeleteUser(rowData)

                                      },
                                    },
                                  ]}
                                  localization={{
                                    pagination: {
                                      nextAriaLabel: "Next Page",
                                      labelRowsPerPage: " ",
                                    },
                                  }}
                                  onRowClick={(event, rowData, togglePanel) => {
                                    return (
                                      UpdateMenuClicked("moredetails"),
                                      MoreInfodata(rowData),
                                      settransindividual(rowData)
                                    );
                                  }}
                                />
                              </div>
                          
                         
                        </div>
                      </ThemeProvider>
                    </div>
                  )}
                </div>
              </main>
            </div>
          </div>
        </body>
      )}
    </>
  );
}

export default Alladmin;
