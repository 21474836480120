import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../../Components/Styles.css";

import CountrySelect from "../../Components/CountrySelect";

import Piedata from "../../Components/Piedata";

import { DashboardContext } from "../../Context/DashboardContext";
import moment from "moment";
import { useGetPaymentData } from "../../api/usePaymentHistory";

import Analytics from "../Analytics";
import Paymenthistory from "../Paymenthistory";
import Packages from "../Packages";


function Home() {
  
  const { analytics } =
    useContext(DashboardContext);

  const Analyticsdata = [
    {
      value: analytics?.data?.data?.total_users,
      name: "Total Users",
    },
    {
      value: analytics?.data?.data?.total_memorials,
      name: "Total Memorials",
    },
    {
      value: analytics?.data?.data?.total_view_count,
      name: "Total View Count",
    },
    {
      value: analytics?.data?.data?.total_tribute,
      name: "Total Tributes",
    },
    {
      value: analytics?.data?.data?.total_story,
      name: "Total Stories",
    },
    {
      value: analytics?.data?.data?.total_images,
      name: "Total Images",
    },
    {
      value: analytics?.data?.data?.sales_count,
      name: "Daily sales count",
    },
    {
      value: analytics?.data?.data?.suspended_memorials,
      name: "Suspended Memorials",
    },
    {
      value: analytics?.data?.data?.banned_users,
      name: "Banned Users",
    },
  ];



  return (
    <div>



      <Analytics data={Analyticsdata} title="Home" subtitle="ANALYTICS" />

      <Paymenthistory />

      <Packages />
     
    </div>
  );
}

export default Home;
