import React, { useContext, useEffect } from "react";
import useTransIndividual from "../../api/useTransIndividual";
import { DashboardContext } from "../../Context/DashboardContext";
import { Routes, Route, useParams } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useTransaction from "../../api/useTransaction";

function IndividualPage() {
  let { id } = useParams();
  let navigate = useNavigate();

  const {
    transindividual,
    singleuser,
    setSingleuser,
    settransindividual,
    infodata,
    transdetails,
  } = useContext(DashboardContext);

  const { data, isLoading, refetch, isSuccess } = useTransIndividual(id);
  const { refetch: refetchTrans, isLoading: isLoadingTrans, data: dataTrans, isSuccess: success } = useTransaction();


  // useEffect(() => {
  //   console.log(singleuser, "singleuser");

  //   console.log(id, "userid");
  // }, []);

  var host = window.location.protocol + "//" + window.location.host;
  // var temporary_host = "https://dev-ctb.netlify.app"
  var temporary_host = "https://createtribute.com";
  const link_to_share = `${temporary_host}/memorial/${data?.slug}`;




  useEffect(() => {

    console.log(data, 'data')
    // console.log(data_trans)
  }, []);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <body>
          <Navbar />
          <div>
            <div
              className="relative min-h-screen md:flex"
              data-dev-hint="container"
            >
              <input type="checkbox" id="menu-open" className="hidden" />

              <header
                className="bg-gray-600 text-gray-100 flex justify-between md:hidden"
                data-dev-hint="mobile menu bar"
              >
                <a
                  href="#/"
                  className="block p-4 text-white font-bold whitespace-nowrap truncate"
                >
                  {/* Create Tribute */}
                </a>

                <label
                  for="menu-open"
                  id="mobile-menu-button"
                  className="m-2 p-2 focus:outline-none hover:text-white hover:bg-gray-700 rounded-md"
                >
                  <svg
                    id="menu-open-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    id="menu-close-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </label>
              </header>

              <aside
                id="sidebar"
                className=" zindexproperty text-gray-100     space-y-6  px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
                data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation"
              >
                <Sidebar transclcik="trans" />
              </aside>

              <div className=" bg-black  bgclass "></div>

              <main
                id="content"
                className="flex-1 mt-8  xl:mr-12 md:w-12 sm:mr-0    bg-sidebar   "
              >
                {/* <div className="max-w-7xl mx-auto"> */}
                <div className=" mx-auto">
                  {isLoading ? (
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 2,
                      }}
                      open={isLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    <div>
                      <div></div>

                      <div className="pt-7">
                        <div className="bg-sidebar flex-auto   ">
                          <div onClick={() => navigate(-1)} className=" shadow-bxshadow-container   ">
                            <h1 className="font-inter pr-10 pl-10 text-xs  cursor-pointer  not-italic pb-5  ">
                              Manage Transaction/{" "}
                              <span className=" text-[#FF7900] cursor-pointer ">
                                Transaction details
                              </span>
                            </h1>
                          </div>

                          <div>
                            <div className="pr-10 pl-10 pt-8  ">
                              <h1 className="analytics">TRANSACTION DETAILS</h1>
                            </div>
                          </div>

                          <div
                            className="
                             pt-8 sm:pr-0 md:pr-0
                                             "
                          >
                            <div
                              className="
pr-10 md:pr-0 sm:pr-0 pl-10 pt-8  bg-[#FCFCFC] h-fit rounded borderbtm
"
                            >
                             <p className="font-inter pb-4 text-[20px] not-italic text-[#333333]">
                              Name: {dataTrans?.fullname}{" "}
                              </p>


                              <p className=" font-inter pb-4 text-[20px]   not-italic text-[#333333]  ">
                                Email: {data?.email}{" "}
                              </p>

                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                Plan: {data?.plan}{" "}
                              </p>

                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                Amount: {data?.amount}{" "}
                              </p>

                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                Payment Date:{" "}
                                {moment(data?.payment_date)?.format(
                                  "DD/MM/YYYY"
                                )}
                              </p>

                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                Expiration Date: {data?.expiration_date}{" "}
                              </p>

                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                Memorial URL:
                                <a
                                  style={{
                                    color: "blue",
                                    cursor: "pointer"
                                  }}
                                  href={link_to_share}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {link_to_share}
                                </a>
                              </p>
                            </div>

                            <div
                              className="
                                pr-10 pl-10 pt-8 md:pr-0 sm:pr-0  bg-[#FCFCFC] h-fit rounded
                                       "
                            >
                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                Reference: {data?.reference}{" "}
                              </p>

                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                IP Address: {data?.ip_address}
                              </p>

                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                Location: {data?.location}
                              </p>


                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                Currency: {data?.currency}
                              </p>

                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                              Payment Gateway: {dataTrans?.payment_gateway}
                              </p>
                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                              Payment Reference: {dataTrans?.payment_reference}
                              </p>
                              <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                              Ip_address: {data?.ip_address}
                              </p>
                             
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </main>
            </div>
          </div>
        </body>
      )}
    </>
  );
}

export default IndividualPage;
