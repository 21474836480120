import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import AddAdmin from "./Pages/Home/AddAdmin";
import Alladmin from "./Pages/Home/Alladmin";
import AllPlans from "./Pages/Home/AllPlans";
import AllUsers from "./Pages/Home/AllUsers";
import BannedImages from "./Pages/Home/BannedImages";
import BannedStories from "./Pages/Home/BannedStories";
import BannedTribute from "./Pages/Home/BannedTribute";
import Bannedusers from "./Pages/Home/Bannedusers";
import Freeplan from "./Pages/Home/Freeplan";
import IndividualPage from "./Pages/Home/IndividualPage";
import LifeTime from "./Pages/Home/LifeTime";
import ManageAdmin from "./Pages/Home/ManageAdmin";
import Manageimages from "./Pages/Home/Manageimages";
import ManageStories from "./Pages/Home/ManageStories";
import ManageTribute from "./Pages/Home/ManageTribute";
import MemorialPage from "./Pages/Home/MemorialPage";
import PlansPage from "./Pages/Home/PlansPage";
import SingleAdmin from "./Pages/Home/SingleAdmin";

import TestingPage from "./Pages/Home/TestingPage";
import Yearly from "./Pages/Home/Yearly";
import Layout from "./Pages/Layout";
import Login from "./Pages/LoginHome/Login";
import UpdateMemorial from "./Pages/UpdateMemorial";
import ProtectedRoute from "./route/ProtectedRoute";
import ChangePassword from "./Pages/ChangePassword";


function Routing() {
  return (
    <div>
      <BrowserRouter>
        {/* <Sidebar/> */}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/transact" element={<TestingPage />} />
          {/* <Route path="/" element={<Layout/>}/> */}
          <Route path="/dashboard" element={



            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>

          } />
          <Route path="/moredetails/:id" element={


            <ProtectedRoute>
              <IndividualPage />
            </ProtectedRoute>
          } />
          <Route path="/singleadmin/:id" element={



            <ProtectedRoute>
              <SingleAdmin />
            </ProtectedRoute>

          } />
          <Route path="/freeplan" element={

            <ProtectedRoute>
              <Freeplan />
            </ProtectedRoute>


          }



          />
          <Route path="/yearlyplan" element={


            <ProtectedRoute>
              <Yearly />
            </ProtectedRoute>

          } />


          <Route path="/updatememorial" element={


            <ProtectedRoute>
              <UpdateMemorial />
            </ProtectedRoute>

          } />


          <Route path="/lifeplan" element={



            <ProtectedRoute>
              <LifeTime />
            </ProtectedRoute>

          } />
          <Route path="/managememorial" element={


            <ProtectedRoute>
              <MemorialPage />
            </ProtectedRoute>

          } />
          <Route path="/managestories" element={



            <ProtectedRoute>
              <ManageStories />
            </ProtectedRoute>

          } />
          <Route path="/bannedstories" element={



            <ProtectedRoute>
              <BannedStories />
            </ProtectedRoute>

          } />
          <Route path="/managetribute" element={



            <ProtectedRoute>
              <ManageTribute />
            </ProtectedRoute>

          } />
          <Route path="/bannedtributes" element={

            <ProtectedRoute>
              <BannedTribute />
            </ProtectedRoute>
          } />
          <Route path="/bannedimages" element={




            <ProtectedRoute>
              <BannedImages />
            </ProtectedRoute>

          } />
          <Route path="/manageimages" element={



            <ProtectedRoute>
              <Manageimages />
            </ProtectedRoute>

          } />
          <Route path="/manageadmin" element={



            <ProtectedRoute>
              <ManageAdmin />
            </ProtectedRoute>


          } />
          <Route path="/alladmin" element={




            <ProtectedRoute>
              <Alladmin />
            </ProtectedRoute>

          } />

          <Route path="/planspage/:id" element={




            <ProtectedRoute>
              <PlansPage />
            </ProtectedRoute>

          } />
          <Route path="/addadmin" element={

            <ProtectedRoute>
              <AddAdmin />
            </ProtectedRoute>

          } />
          <Route path="/users" element={

            <ProtectedRoute>
              <AllUsers />
            </ProtectedRoute>

          } />

          <Route path="/plans" element={

            <ProtectedRoute>
              <AllPlans />
            </ProtectedRoute>

          } />



<Route path="/changepassword" element={

<ProtectedRoute>
  <ChangePassword />
</ProtectedRoute>

} />

          <Route path="/bannedusers" element={

            <ProtectedRoute>
              <Bannedusers />
            </ProtectedRoute>


          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Routing;
