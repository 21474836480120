import { useMutation } from 'react-query';
import AuthAxios from '../utils/AuthAxios';
import { Axios } from '../utils/Axios';



const addAdmin = (user) => {
  return AuthAxios.post(`/admin/add`, user)
}

// const verify = (user) => {
//   return Axios.post(`/user/otp/verify`, user)
// }

export const useRegisterAdmin = (onSuccess, onError) => {
  return useMutation(addAdmin, {
    onError: onError,
    onSuccess: onSuccess
  })
}

// export const useVerifyOTP = (onSuccess, onError) => {
//   return useMutation(verify, {
//     onError: onError,
//     onSuccess: onSuccess
//   })
// }

