import React, { useContext } from "react";
import { useEffect } from "react";
import { DashboardContext } from "../Context/DashboardContext";
import Home from "../Pages/Home/Home";

function Body() {

  const { UpdateMenuClicked } = useContext(DashboardContext);

  useEffect(() => {
    UpdateMenuClicked("home");

  }, []);


  return (
    <div className="  pt-7 pb-5    ">
      <Home />
    </div>
  );
}

export default Body;
