import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const banstoryuser = async (id) => {
    const response = await AuthAxios?.get(`/story/ban/${id}`)
    const data = response.data.data
    return data
  }



export default function useBanStory(id) {

    const {setBanStory,} = useContext(DashboardContext);
      return useQuery('ban story', ()=> banstoryuser(id), {
        refetchOnWindowFocus: false ,
        enabled: false,
        
        onSuccess: (data, message) => {
            //  console.log(data, "Baned response")
             setBanStory(data);
          },


          onError: (error)=>{
            // console.log(error, "error occured")
          }

      })
}
