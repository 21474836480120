import React from "react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
Chart.register(CategoryScale);

export function PieChart() {
  const data = {
    labels: ['FREEMIUM', 'YEARLY', 'LIFETIME'],
    datasets: [{
      label: 'My First Dataset',
      data: [300, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  };

  const config = {
    type: 'pie',
    data: data,
  };

  return (
    <div style={{ width: '500px', height: '400px' }}>
      <Pie data={data} options={config} />
    </div>
  );
}
