import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Local_storage } from "../utils/LocalStorageConfig";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    var myHeaders = new Headers();
    myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${Local_storage()?.get('_utk')}`
    );

    var formdata = new FormData();
    formdata.append("current_password", currentPassword);
    formdata.append("new_password", newPassword);
    formdata.append("new_password_confirmation", confirmPassword);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.createtribute.com/admin/admin/password/change",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        // Check the response for successful password change or any error messages
        if (res.message === "Password Changed Successfully") {
          Swal.fire({
            icon: "success",
            iconColor: "#FF7900",
            text: `${res.message}`,
            confirmButtonColor: "#FF7900",
            timer: 3000,
          });
          // Clear form fields after successful password change
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          navigate("/");
        } else {
          // Display error message if password change was unsuccessful
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${res.message}`, // Update this with the appropriate field from the API response
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error scenarios here
        setSubmitting(false);
      });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="border-2 border-orange-400 p-10 sm:mx-auto sm:w-full sm:max-w-sm" style={{ borderColor: '#FFA500' }}>
        <h2 className="mt-0 mb-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Change Password
        </h2>

        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="currentPassword" className="block text-sm font-medium leading-6 text-gray-900">
              Current Password
            </label>
            <div className="mt-2">
              <input
                id="currentPassword"
                name="currentPassword"
                type="password"
                autoComplete="current-password"
                required
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900">
              New Password
            </label>
            <div className="mt-2">
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                autoComplete="new-password"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
              Confirm Password
            </label>
            <div className="mt-2">
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="confirm-password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              style={{ backgroundColor: '#FFA500' }}
              disabled={submitting}
            >
              {submitting ? "Submitting" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
