import { createContext, useContext, useState } from 'react';

export const DashboardContext = createContext();



export default function DashProvider({ children }) {
  const [MenuClicked, setMenuClicked] = useState("home")
  const [dashdata, setdashdata] = useState()
  const [infodata, setInfodata] = useState()
  const [analytics, setanalytics] = useState()
  const [analytichistory, setanalytichistory] = useState()
  const [logout, setLogout] = useState(false)
  const [packagelast, setpackagelast] = useState()
  const [paymentinfo, setpaymentinfo] = useState()
  const [transdetails, settransdetails] = useState()
  const [transindividual, settransindividual] = useState()
  const [filterdata, setfilterdata] = useState()
  const [singleuser, setSingleuser] = useState()
  const [freeplan, setfreeplan] = useState()
  const [yearlyplan, setyearlyplan] = useState()
  const [lifetimeplan, setlifetimeplan] = useState()
  const [viewresponse, setViewResponse] = useState()
  const [deleteuser, setDeleteUser] = useState()
  const [deletestory, setDeleteStory] = useState()
  const [banstory, setBanStory] = useState()
  const [storiesdata, setstoriesdata] = useState()
  const [storyanalytics, setstoryanalytics] = useState()
  const [bannedusers, setbannedusers] = useState()
  const [tributedata, settributedata] = useState()
  const [deletetribute, setDeletetribute] = useState()
  const [bantribute, setBanTribute] = useState()
  const [allimages, setallimages] = useState()
  const [deleteimage, setDeleteImage] = useState()
  const [BannedImages, setBannedImages] = useState()
  const [bannedimagesdata, setbannedimagesdata] = useState()
  const [alladmin, setalladmin] = useState()
  const [singleAdmin, setsingleAdmin] = useState()
  const [deleteadmin, setDeleteAdmin] = useState()
  const [allusers, setAllusers] = useState()
  const [getallbannedusers, setallbannedUsers] = useState()
  const [filterdataplan, setfilterdataplan] = useState()
  const [suspendmemorial, setSuspendMemorial] = useState()




  const UpdateMenuClicked = (data) => {
    setMenuClicked(data)
  }

  const UpdateGetPackagelast = (data) => {
    setpackagelast(data)
  }

  const LogoutFunction = (data) => {
    setLogout(data);
  };


  const MoreInfodata = (data) => {
    setInfodata(data)
  }


  const AnalyticData = (data) => {
    setanalytichistory(data)
  }


  const DashboardData = (data) => {
    setdashdata(data)
  }



  const UpdateAnalytics = (data) => {
    setanalytics(data)
  }


  const PaymentData = (data) => {
    setpaymentinfo(data)
  }


  const Singlefiltered = (data) => {
    setSingleuser(data)
  }



  return (
    <DashboardContext.Provider value={{
      MenuClicked, setMenuClicked, UpdateMenuClicked, dashdata, DashboardData, MoreInfodata, infodata, analytics, setanalytics
      , LogoutFunction, logout, setLogout, analytichistory, setanalytichistory, AnalyticData, UpdateGetPackagelast, UpdateAnalytics, packagelast, setpackagelast, paymentinfo, setpaymentinfo, PaymentData, transdetails, settransdetails, filterdata, singleuser,
      setSingleuser, setfilterdata,
      transindividual, settransindividual,
      deleteuser, setDeleteUser, Singlefiltered, freeplan,
      viewresponse, setViewResponse, setfreeplan, yearlyplan, setyearlyplan,
      lifetimeplan, setlifetimeplan,
      storiesdata, setstoriesdata,
      storyanalytics, setstoryanalytics,
      deletestory, setDeleteStory,
      banstory, setBanStory,
      bannedusers, setbannedusers, tributedata, settributedata, deletetribute, setDeletetribute, bantribute, setBanTribute,
      allimages, setallimages, deleteimage, setDeleteImage, BannedImages, setBannedImages, bannedimagesdata, setbannedimagesdata,
      alladmin, setalladmin, singleAdmin, setsingleAdmin, deleteadmin, setDeleteAdmin, allusers, setAllusers, getallbannedusers,
      setallbannedUsers, filterdataplan, setfilterdataplan, suspendmemorial, setSuspendMemorial
    }}>
      {children}

    </DashboardContext.Provider>
  )
}





