import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const bantributeuser = async (id) => {
    const response = await AuthAxios?.get(`/tribute/ban/${id}`)
    const data = response.data.data
    return data
  }



export default function useBanTribute(id) {

    const {bantribute, setBanTribute,} = useContext(DashboardContext);
      return useQuery('ban tribute', ()=> bantributeuser(id), {
        refetchOnWindowFocus: false ,
        enabled: false,
        
        onSuccess: (data, message) => {
            //  console.log(data, "Baned response")
             setBanTribute(data);
          },


          onError: (error)=>{
            // console.log(error, "error occured")
          }

      })
}
