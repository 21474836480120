import { useContext } from "react";
import { useQuery } from "react-query";
import { DashboardContext } from "../Context/DashboardContext";
import AuthAxios from "../utils/AuthAxios";

const getstoryanalytics = async () => {
  const response = await AuthAxios.get(`/story/analytics`);
  const data = response;
  return data;
};

export const useGetStoryAnalytics = () => {
  const { setstoryanalytics } = useContext(DashboardContext);
  return useQuery("fetch story analytics ", () => getstoryanalytics(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
        // console.log(data, "/story/analytics")
    setstoryanalytics(data);

    },
  });
};
