import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { AiFillEyeInvisible } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
// import GoogleBtn from "../../components/Buttons/GoogleBtn";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useForm, Controller } from "react-hook-form";
// import InputField from "../../components/InputField/InputField";
import "./Login.css";
// import PrimaryBtn from "../../components/Buttons/PrimaryBtn";
import { useNavigate } from "react-router-dom";
import Union3 from '../../Assets/Union3.svg'
import Logowhite1 from '../../Assets/Logowhite1.svg'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoginUser } from "../../api/useLogin";
import { Link } from "react-router-dom";
import { Local_storage } from "../../utils/LocalStorageConfig";
import { Capitalizer } from "../../utils/Capitalizer";
import { PuffLoader, PulseLoader } from "react-spinners";
import InputField from "../../Components/InputField/InputField";
// import GoogleBtn from "../../Assets/Nigeria.png";
// import PrimaryBtn from "../../Assets/Nigeria.png";
import ErrorPopup from "../../Components/SuccessPopup/ErrorPopup";
import GoogleBtn from "../../Components/Buttons/GoogleBtn";
import PrimaryBtn from "../../Components/Buttons/PrimaryBtn";
// import PrimaryBtn from "../../components/Buttons/PrimaryBtn";;
import Unionmain from '../../Assets/Unionmain.svg'


function Login() {
  const [showPassword, setshowPassword] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
      .required("Valid email is required"),
    password: yup.string().required("Password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const [isLoginError, setisLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSuccess = (item) => {
    // console.log(item, "item")
    Local_storage().set("_utk", item?.data?.data?.token)
    Local_storage().set("_na_ma", Capitalizer(item?.data?.data?.user?.first_name) + " " + Capitalizer(item?.data?.data?.user?.last_name))
    navigate('/dashboard')
  };

  const onError = (error) => {
    // console.log(error, "errorr")
    setErrorMessage(error?.response?.data?.message);
    setisLoginError(true);
  };

  const handleCloseError = () => {
    setisLoginError(false);
  };



  const { mutate: LoginUser, isLoading } = useLoginUser(onSuccess, onError);

  const handleLogin = async (data) => {
    LoginUser(data);

  };

  return (
    <div className="login_container">
      {/* <div className="login_left">
        <img alt="logo_img" onClick={()=>{
          navigate('/')
        }}  className="object_logo" src={Logowhite1} />
        <div className="textCont">
          <h1>Create beautiful memories.</h1>
        </div>
        <object  className="object_svg2" data={Union3} type="image/svg+xml" />
      </div> */}
      <div className="login_left">
        <img alt="logo_img" onClick={() => {
          navigate('/')
        }} className="object_logo" src="https://dev-ctb.netlify.app/static/media/Logowhite.d9fe300fc172cfe93ee5.png" />

        <div className="textCont">
          <h1>Preserving Legacies of Our Loved Ones.</h1>
        </div>
        <object className="object_svg2" data={Unionmain} type="image/svg+xml" />
      </div>
      <div className="login_right">
        <div className="right_container">
          <div className="login_head">
            <h1>CreateTribute Admin Login</h1>
            {/* <p>
              Don’t have an account?{" "}
              <span onClick={() => navigate("/sign-up")}>Sign Up</span>{" "}
            </p> */}
          </div>
          {/* <div> */}
          {/* <Grid container spacing={2} className="form_container">
            <Grid item xs={6}>
              <GoogleBtn
                icon="google"
                txt="Continue With Google"
                onClick={() => console.log("clicked google")}
              />
            </Grid>
            <Grid item xs={6}>
              <GoogleBtn
                icon="fb"
                txt="Continue With Facebook"
                onClick={() => console.log("clicked facebook")}
              />
            </Grid>
          </Grid> */}
          {/* </div> */}
          <div className="form_container">
            <form
              style={{ width: "100%" }}
              onSubmit={handleSubmit(handleLogin)}
            >
              <Grid container spacing={10}>
                <Grid item xs={8}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        {...field}
                        error={errors.email}
                        type="text"
                        id="email"
                        label="Email"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8} style={{ marginTop: '-50px' }}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <InputField
                        {...field}
                        error={errors.password}
                        type="password"
                        id="password"
                        label="Password"
                        // endAdornment={
                        //   <InputAdornment position="end">
                        //     <IconButton
                        //       onClick={() => setshowPassword(!showPassword)}
                        //     >
                        //       {showPassword ? (
                        //         <AiFillEyeInvisible size={20} />
                        //       ) : (
                        //         <MdVisibility size={20} />
                        //       )}
                        //     </IconButton>
                        //   </InputAdornment>
                        // }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={8}>
                  <div style={{
                    display: "flex", justifyContent: "space-between", flexWrap: "wrap"
                  }} className="remember-forgetpassword">
                    <div className="remember">
                      {/* <span className="lgCheckBoxCon">
                        <input type="checkbox" className="lgcheckBox"></input>
                        <span style={{ marginLeft: "10px" }}>Remember?</span>
                      </span> */}
                    </div>

                    <div className="forgotpass">
                      {/* <span>
                        <Link to="/forgetpass">
                          <span style={{ color: "var(--main)" }}>
                            Forgot password?{" "}
                          </span>
                        </Link>
                      </span> */}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={8} style={{ marginTop:'-110px'}}>
                  <PrimaryBtn
                    txtColor="White"
                    pd="11px"
                    br="10px"
                    w="100%"
                    mt="300px"
                    bg="#FF7900"
                    hoverBG="#FF7900"
                    txt={isLoading ? <PulseLoader color="white" loading={isLoading} speedMultiplier={1} size={20} /> : "SIGN IN"}
                    fw="700"
                    fs="17px"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>

      <ErrorPopup
        open={isLoginError}
        close={handleCloseError}
        tryAgain={handleCloseError}
        title="Error"
        message={`Oppz! ${errorMessage}`}
      />
    </div>
  );
}

export default Login;
