import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const deleteuserimage = async (id) => {
    const response = await AuthAxios?.get(`/image/delete/${id}`)
    const data = response.data.data
    return data
  }



export default function useImagedelete(id) {

    const { setDeleteImage,} = useContext(DashboardContext);

      return useQuery('delete image', ()=> deleteuserimage(id), {
        refetchOnWindowFocus: false ,
        enabled: false,
        
        onSuccess: (data, message) => {
            //  console.log(data, "deleted response")
             setDeleteImage(data);

          },


          onError: (error)=>{
            // console.log(error, "error occured")
          }

          


      })
}
