import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchLifePlan = async (id) => {
  const response = await AuthAxios?.get(`/memorial/${3}`)
  const data = response.data.data
  return data
}



export default function useLifeTime(id) {

  const { lifetimeplan, setlifetimeplan } = useContext(DashboardContext);
  return useQuery('fetch LifePlan plan', () => fetchLifePlan(id), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      //  console.log(data, "LifePlan plandata")

      setlifetimeplan(data);

      // lifetimeplan, setlifetimeplan

    },
  })
}
