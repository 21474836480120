import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchtribute = async (id) => {
    const response = await AuthAxios?.get(`/tribute`)
    const data = response.data.data
    return data
  }



export default function useTribute() {

    const {tributedata, settributedata} = useContext(DashboardContext);

      return useQuery('fetch tribute', ()=>fetchtribute(), {
        refetchOnWindowFocus: false ,
        onSuccess: (data) => {
            //  console.log(data, "/tribute")
           
            settributedata(data);
  
          },
      })
}
