import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const deletetributeuser = async (id) => {
    const response = await AuthAxios?.get(`/tribute/delete/${id}`)
    const data = response.data.data
    return data
  }



export default function useTributedelete(id) {

    const {deletetribute, setDeletetribute,} = useContext(DashboardContext);
      return useQuery('delete tribute', ()=> deletetributeuser(id), {
        refetchOnWindowFocus: false ,
        enabled: false,
        
        onSuccess: (data, message) => {
            //  console.log(data, "deleted response")
             setDeletetribute(data);
          },

          onError: (error)=>{
            // console.log(error, "error occured")
          }

      })
}
