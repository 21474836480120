import { useContext } from "react";
import { useQuery } from "react-query";
import { DashboardContext } from "../Context/DashboardContext";
import AuthAxios from "../utils/AuthAxios";

const getdashboardata = async () => {
  const response = await AuthAxios.get(`/dashboard/analytics`);
  const data = response;
  return data;
};

export const useGetDashboardData = () => {
  const { UpdateAnalytics } = useContext(DashboardContext);
  return useQuery("fetch dash data ", () => getdashboardata(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      UpdateAnalytics(data);
    },
  });
};
