import React, { forwardRef, useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { useTransdelete } from "../../api/useTransdelete";

import { jsPDF } from "jspdf";
import { useFilter } from "../../api/useFilter";
import moment from "moment";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { DashboardContext } from "../../Context/DashboardContext";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import useFreePlan from "../../api/useFreePlan";

import deletesvg from "../../Assets/deletesvg.svg";
import useView from "../../api/useView";

import useStory from "../../api/useStory";
import { useGetStoryAnalytics } from "../../api/useStoryAnalytics";
import Analytics from "../Analytics";
import DropdownStory from "../../Components/DropdownStory";
import useStorydelete from "../../api/useStorydelete";
import useBanStory from "../../api/useBanStrory";
import useImages from "../../api/useImages";
import DropdownImages from "../../Components/DropdownImages";
import useImagedelete from "../../api/useImagedelete";
import useBannedImagesApi from "../../api/UseBannedImagesApi";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PrimaryBtn from "../../Components/PrimaryBtn";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { useRegisterAdmin } from "../../api/UseAddAdmin";
import SuccessPopup from "../../Components/SuccessPopup";
import ErrorPopup from "../../Components/SuccessPopup/ErrorPopup";
import { ClipLoader } from "react-spinners";


function ManageAdmin() {
  const [value, setValue] = useState();
  const [phoneErr, setphoneErr] = useState(false);
  const [country, setCountry] = useState("");
  const [countryErr, setcountryErr] = useState(false);
  const [isRegError, setisRegError] = useState(false);
  const [isRegSuccess, setisRegSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [role, setrole] = useState(" ");

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup.object().shape({
    first_name: yup
      .string()
      .required("first_name is required")
      .min(3, "Can't be lesser than 3 digits")
      .max(75, "Can't exceed 75 digits")
      .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
    phone: yup.string().required("Phone number is required"),
    last_name: yup
      .string()
      .required("last_name is required")
      .min(3, "Can't be lesser than 3 digits")
      .max(75, "Can't exceed 75 digits")
      .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
    email: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
        "Valid email is required"
      )
      .required("Valid email is required"),
    password: yup.string().required("Password is required"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Password mismatch!")
      .required("Required"),
  });

  const {
    control,
    getValues,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const defaultMaterialTheme = createTheme();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    {
      title: "Image",
      field: "image",

      render: (image_urls) => (
        <div
          // onClick={() => {
          //   history.push(
          //     GlobalizationLanguagewithCOntryCode(`/open-delivery/${image_urls?.delivery_id}`   , country, visitingCountryCode));
          // }}
          className="myImage"
        >
          <div className="innerimg">
            <img
              className="table-images"
              src={image_urls?.image}
              alt={image_urls?.added_by}
            />
          </div>
        </div>
      ),
    },
    {
      title: "Date",
      field: "date",

      render: (rowData) => (
        <div>
          <p>{moment(rowData?.date)?.format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    { title: "Added By", field: "added_by" },

    {
      title: "Action",
      field: "more",

      render: (rowData) => (
        <div>
          <DropdownImages />
        </div>
      ),
    },
  ];

  const onSuccess = (data) => {
    setisRegSuccess(true);
    // console.log("added successfully",  console.log(data))
    resetField("first_name");
    resetField("email");
    resetField("phone");
    resetField("last_name");
    resetField("password");
    resetField("password_confirmation");
  };

  const onError = (error) => {
    setErrorMessage(error.response.data.message);
    setisRegError(true);
    resetField("first_name");
    resetField("email");
    resetField("phone");
    resetField("last_name");
    resetField("password");
    resetField("password_confirmation");
  };

  const handleCloseError = () => {
    setisRegError(false);
  };

  const handleClose = () => {
    setisRegSuccess(false);
    // window.location.reload();
    // navigate('/verify-account', {
    //      state : {
    //         email : getValues('email'),
    //         from: "fromsignup"
    //         }
    //     }
    // )
  };

  const { mutate: RegisterAdmin, isLoading } = useRegisterAdmin(
    onSuccess,
    onError
  );

  const register_admin = (data) => {
    console.log(data, "datattatata");
    // console.log(role)
    // if (country === "") {
    //   setcountryErr(true)
    // }
    RegisterAdmin(data)

  };

  const HandleRole = (e) => {
    setrole(e.target.value);
  };

  return (
    <>
      <div style={{overflowX:"hidden"}}>
        {/* {isloadingbanimage || isloadingimage ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
          open={isloadingimage}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : ( */}
        <body>
          <Navbar />
          
          <div
            className="relative min-h-screen md:flex"
            data-dev-hint="container"
          >
            <input type="checkbox" id="menu-open" className="hidden" />

            <header
              className="bg-gray-600 text-gray-100 flex justify-between md:hidden"
              data-dev-hint="mobile menu bar"
            >
              <a
                href="#/"
                className="block p-4 text-white font-bold whitespace-nowrap truncate"
              >
                {/* Create Tribute */}
              </a>

              <label
                for="menu-open"
                id="mobile-menu-button"
                className="m-2 p-2 focus:outline-none hover:text-white hover:bg-gray-700 rounded-md"
              >
                <svg
                  id="menu-open-icon"
                  className="h-6 w-6 transition duration-200 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  id="menu-close-icon"
                  className="h-6 w-6 transition duration-200 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </label>
            </header>

            <aside
              id="sidebar"
              className=" zindexproperty text-gray-100     space-y-6  px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
              data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation"
            >
              <Sidebar arrowclick="admin" Admin="admin" />
            </aside>

            <div className=" bg-black  bgclass "></div>

            <main
  id="content"
  className="flex-1 mt-10 xl:mr-12 md:w-12 sm:mr-0 justify-center items-center bg-sidebar"
  style={{
    marginBottom: '50px',
    height: 'calc(180vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  }}
>
            <div className="mx-auto w-full max-w-screen-lg">
              <div className="pt-7">
           
                  <div></div>

                  <ThemeProvider theme={defaultMaterialTheme}>
                    
                      {/* <div className=" shadow-bxshadow-container   ">
                          <h1 className="font-inter pr-10 pl-10   not-italic pb-5 boldtitle  ">
                            Manage Memorials
                          </h1>
                        </div> */}

<div className="mx-auto w-full max-w-screen-lg">
    <div className="pt-7">
      <div className="shadow-bxshadow-container">
        <h1 className="font-inter pr-10 pl-10 not-italic pb-5 boldtitle">
          Manage Admins
        </h1>
      </div>

                     <div>
                        <div className="pr-10 pl-10 pt-8  ">
                       <h1 className="analytics">ADD ADMIN</h1>
                    </div>
                  </div>
                      </div>

                      <div style={{ maxWidth: "100%" }}>
                        <div
                          style={{
                            marginBottom: "0px",
                            zIndex: "",
                            position: "relative",
                            width: "100%",
                            maxWidth: "300px",
                          }}
                        >
                          {/* <div>
                            <div className="pr-10 pl-10 pt-8  ">
                              <h1 className="analytics">ADD ADMIN</h1>
                            </div>
                          </div> */}
                        </div>

                        <div className="pr-10 pl-10 pt-8  ">
                          <div className="adminform">
                            <form
                              action=""
                              style={{ width: "100%" }}
                              onSubmit={handleSubmit(register_admin)}
                            >
                              <div className="create_input" style={{marginLeft:"260px"}}>
                                <Controller
                                  name="first_name"
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <div>
                                      <div className="parentdiv">
                                        <input
                                          //  {...register("first_name")}
                                          {...field}
                                          className="input_name"
                                          type="text"
                                          placeholder="First Name"
                                        />
                                        <span style={{ color: "red" }}>
                                          {errors?.first_name?.message}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                />

                                <Controller
                                  name="last_name"
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <div>
                                      <div className="parentdiv">
                                        <input
                                          //  {...register("first_name")}
                                          {...field}
                                          className="input_name"
                                          type="text"
                                          placeholder="Last Name"
                                        />
                                        <span style={{ color: "red" }}>
                                          {errors?.last_name?.message}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                />

                                {/* <input
                                  {...register("email")}
                                  className="input_name"
                                  type="email"
                                  placeholder="Email"
                                /> */}
                                <Controller
                                  name="email"
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <div>
                                      <div className="parentdiv">
                                        <input
                                          //  {...register("first_name")}
                                          {...field}
                                          className="input_name"
                                          type="email"
                                          placeholder="Email"
                                        />
                                        <span style={{ color: "red" }}>
                                          {errors?.email?.message}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                />



                                <Controller
                                  name="phone"
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <div>
                                      <div className="parentdiv">
                                        <input
                                          //  {...register("first_name")}
                                          {...field}
                                          className="input_name"
                                          // type="tel"
                                          placeholder="Phone number"
                                        />
                                        <span style={{ color: "red" }}>
                                          {errors?.phone?.message}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                />

                                <Controller
                                  name="password"
                                  defaultValue=""
                                  control={control}
                                  render={({ field }) => (
                                    <div>
                                      <div className="parentdiv">
                                        <input
                                          {...field}
                                          className="input_name"
                                          type="password"
                                          placeholder="Password"
                                        />
                                        <span style={{ color: "red" }}>
                                          {errors?.password?.message}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                />

                                <Controller
                                  name="password_confirmation"
                                  defaultValue=""
                                  control={control}
                                  render={({ field }) => (
                                    <div>
                                      <div className="parentdiv">
                                        <input
                                          // {...register("c_password")}
                                          {...field}
                                          className="input_name"
                                          type="password"
                                          placeholder="Confirm Password"
                                        />
                                        <span style={{ color: "red" }}>
                                          {
                                            errors?.password_confirmation
                                              ?.message
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                />

                                <Controller
                                  name="role"
                                  defaultValue=""
                                  control={control}
                                  render={({ field }) => (
                                    <div>
                                      <div className="parentdiv">
                                        {/* <input
                                          // {...register("c_password")}
                                          {...field}

                                          className="input_name"
                                          type="password"
                                          placeholder="Confirm Password"
                                        />
                                        <span style={{ color: "red" }} >{errors?.password_confirmation?.message}</span> */}

                                        <select
                                          {...field}
                                          className="input_name"
                                          name="role"
                                          id="role"
                                        >
                                          <option value="Contry Admin">
                                            Country Admin
                                          </option>
                                          <option value="Super Admin">
                                            Super Admin
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                />

                                <Controller
                                  name="country_id"
                                  defaultValue="2"
                                  control={control}
                                  render={({ field }) => (
                                    <div>
                                      <div className="parentdiv">

                                        <select
                                          {...field}
                                          className="input_name"
                                        // name="country_id"
                                        // id="country_id"
                                        >
                                          <option value="2">Nigeria</option>
                                          <option value="3">Australia</option>
                                          <option value="4">United States</option>
                                        </select>
                                      </div>
                                    </div>
                                  )}


                                  
                          />
                       <div className="create_input">
  {/* Other input elements */}
  {/* Your input fields here */}
  {/* ... */}

  {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <PrimaryBtn
      txtColor="white"
      type="submit"
      pd="8px"
      br="8px"
      w="120px"
      bg="#FF7900"
      hoverBG="#FF7900"
      txt={
        isLoading ? (
          <ClipLoader
            color="white"
            loading={isLoading}
            speedMultiplier={1}
            size={20}
          />
        ) : (
          "Register"
        )
      }
      fw="500"
      style={{
        padding: '8px',
        borderRadius: '8px',
        width: '120px',
        backgroundColor: '#FF7900',
        color: 'white',
        textAlign: 'center',
        fontWeight: '500',
      }}
    />
  </div> */}
  <button
  type="submit"
  style={{
    display:"flex", 
    justifyContent:"center",
    padding: '8px',
    borderRadius: '8px',
    width: '40%',
    backgroundColor: '#FF7900',
    color: 'white',
    textAlign: 'center',
    fontWeight: '500',
    border: 'none',
    cursor: 'pointer',
    
  }}
  onClick={handleSubmit(register_admin)}
  disabled={isLoading}
>
  {isLoading ? (
    <ClipLoader
      color="white"
      loading={isLoading}
      speedMultiplier={1}
      size={20}
    />
  ) : (
    'Register'
  )}
</button>

</div>

                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ThemeProvider>
                </div>
              </div>

              <SuccessPopup
                open={isRegSuccess}
                close={handleClose}
                title="Success"
            
                message="Registration successfully"
              />
              <ErrorPopup
                open={isRegError}
                close={handleCloseError}
                tryAgain={handleCloseError}
                title="Error"
                message={`Oppz! ${errorMessage}`}
              />
            </main>
          </div>
        </body>
      </div>
    </>
  );
}

export default ManageAdmin;