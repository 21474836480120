import React, { useContext, useState, useEffect } from "react";
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useGetLogoutData } from "../api/useLogout";
import { DashboardContext } from "../Context/DashboardContext";
import { Local_storage } from "../utils/LocalStorageConfig";
import DialogClickout from "./DialogClickout";
import "./Styles.css";

function Sidebar({
  transclcik,
  Homeclcik,
  arrowclick,
  Fplan,
  Yplan,
  Lplan,
  Astories,
  Bstories,
  Btributes,
  Atributes,
  Aimages,
  Bimages,
  Admin,
  addamin,
  alladmin,
  Musers,
  Ausers,
  Busers,
  Mplans,
  Aplans,
  Bplans,
}) {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();

  

  const { refetch } = useGetLogoutData();

  
  const [arrowclicks, setArrowClicks] = useState(
    localStorage.getItem('arrowClicks') === 'true'
  );
  const [storiesclicked, setstoriesClicked] = useState(
    localStorage.getItem('manageStories') === 'true'
  );
  const [tributeclicked, settributeClicked] = useState(
    localStorage.getItem('manageTribute') === 'true'
  );
  const [imageclicked, setimageClicked] = useState(
    localStorage.getItem('manageImages') === 'true'
  );
  const [adminClicked ,setadminClicked] = useState(
    localStorage.getItem('manageAdmins') === 'true'
  );
  const [usersClicked,setusersClicked] = useState(
    localStorage.getItem('manageUsers') === 'true'
  );
  const [manageClicked, setmanageClicked] = useState(
    localStorage.getItem('managePlans') === 'true'
  );
  const [Fplans, setFplan] = useState(false);
  const [Yplans, setYplan] = useState(false);
  const [Lplans, setLplan] = useState(false);

  useEffect(() => {
    localStorage.setItem('arrowClicks', arrowclicks);
    localStorage.setItem('manageStories', storiesclicked);
    localStorage.setItem('manageTribute', tributeclicked);
    localStorage.setItem('manageImages', imageclicked);
    localStorage.setItem('manageAdmins', adminClicked);
    localStorage.setItem('manageUsers', usersClicked);
    localStorage.setItem('managePlans', manageClicked);
  }, [arrowclicks, storiesclicked,tributeclicked, imageclicked,adminClicked, usersClicked,manageClicked ]);

 
  

  const handleHomeClicked = () => {
    // setstoriesClicked(false);
    // settributeClicked(false);
    // setimageClicked(false);
    // setadminClicked(false);
    // setusersClicked(false);
    UpdateMenuClicked("home");
    navigate(`/dashboard`);
  };

  const handleTransClicked = () => {
    // setstoriesClicked(false);
    // settributeClicked(false);
    // setimageClicked(false);
    // setadminClicked(false);
    // setusersClicked(false);

    UpdateMenuClicked("trans");
    navigate(`/transact`);
  };

  const handleArrowClick = () => {
    // setstoriesClicked(false);
    // settributeClicked(false);
    // setimageClicked(false);
    // setadminClicked(false);
    // setusersClicked(false);
    
    
    setArrowClicks(!arrowclicks);
    setFplan(false);
    setYplan(false);
    setLplan(false);
  };

  const handleStoriesClicked = () => {
     setstoriesClicked(!storiesclicked);
    // settributeClicked(false);
    // setimageClicked(false);
    // setadminClicked(false);
    // setusersClicked(false);
  };

  const handleTributeClick = () => {
    settributeClicked(!tributeclicked);
    // setstoriesClicked(false);
    // setimageClicked(false);
    // setadminClicked(false);
    // setusersClicked(false);
  };

  const handleImageClick = () => {
    setimageClicked(!imageclicked)
    // setstoriesClicked(false);
    // settributeClicked(false);
    // setimageClicked(!imageclicked);
    // setadminClicked(false);
    // setusersClicked(false);
  };

  const handleFreePlan = () => {
    setFplan(!Fplans);
    navigate(`/freeplan`);
  };

  const handleAllAdmin = () => {
   
    navigate(`/alladmin`);
  };

  const handleAllstories = () => {
    navigate(`/managestories`);
  };

  const handleImages = () => {
    UpdateMenuClicked("allimages");
    navigate(`/manageimages`);
  };

  const handleTributes = () => {
    UpdateMenuClicked("alltributes");
    navigate(`/managetribute`);
  };

  const handleYearlyPlan = () => {
    setYplan(!Yplans);
    navigate(`/yearlyplan`);
  };

  const handleBannedstories = () => {
    UpdateMenuClicked("bannedstories");
    navigate(`/bannedstories`);
  };

  const handleBannedimages = () => {
    UpdateMenuClicked("bannedusers");
    navigate(`/bannedimages`);
  };

  const handleBannedUsers = () => {
    UpdateMenuClicked("bannedimages");
    navigate(`/bannedusers`);
  };

  const handleAdminClick = () => {
   setadminClicked(!adminClicked);
  };
  const handleAdmin =() =>{
    navigate(`/manageadmin`);
  }

  const handleBannedtributes = () => {
    UpdateMenuClicked("bannedtributes");
    navigate("/bannedtributes");
  };

  const handleLifePlan = () => {
    setLplan(!Lplans);

    navigate(`/lifeplan`);
  };

  const handleAllusersclick = () => {
    setusersClicked(!usersClicked);
  };

  const handleAllusers = () => {
    navigate("/users");
  };

  const handleAllplansClick = () => {
   setmanageClicked(!manageClicked);
  };
const handleAllplans = () => {
  navigate("/plans");
}




  const { UpdateMenuClicked } = useContext(DashboardContext);

  const handleClick = () => {
    refetch();
    navigate("/");
    Local_storage().clear("_utk");
  };

  const [changepass, setChangePass] = useState(false)

  const handlechangepassword = () => {
    setChangePass(true)
  }

  const [currentp, setCurrentP] = useState("")
  const [newp, setNewP] = useState("")
  const [newpc, setpc] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const SubmitPlan = (e) => {
    setSubmitting(true)
    e.preventDefault()
    console.log(currentp, newp, newpc)

    var myHeaders = new Headers();
    myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${Local_storage()?.get('_utk')}`);

    var formdata = new FormData();
    formdata.append("current_password", currentp);
    formdata.append("new_password", newp);
    formdata.append("new_password_confirmation", newpc);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://api.createtribute.com/admin/admin/password/change", requestOptions)
      .then(response => response.json())
      .then((res) => {
        console.log(res,)
        setChangePass(false)
        // if (res.message === "Password Changed Successfully") {
        Swal.fire({
          // icon: "success",
          iconColor: "#FF7900",
          text: `${res.message}`,
          confirmButtonColor: "#FF7900",
          timer: 3000,
        });
        setCurrentP("")
        setNewP("")
        setpc("")
        setSubmitting(false)
        //  }
      })
      .catch(
        (err) => {
          console.log(err, 'errrrrr')
          console.log(err?.response, 'errrrrr')
          console.log(err?.errors, 'errrrrrrorrrrr')
          setSubmitting(false)
        }
      );



  }


  return (
    <div className="h-fit  pt-7 pb-5 pr-10 pl-10 mr-8 mt-8  ml-2 sm:pr-8   ">
      <div>
        <div></div>

        <div>
          <div className="flex cursor-pointer justify-between items-center pb-5 pt-2  ">
            <div>
              <h1
                onClick={handleHomeClicked}
                className={`${Homeclcik === "home"
                  ? "border-l-4 cursor-pointer pl-1  text-clicked  "
                  : "text-slate-200 cursor-pointer pl-1 sidecontent "
                  }`}
              >
                Home
              </h1>
            </div>
          </div>

  <div className="flex cursor-pointer justify-between items-center pb-5 pt-2">
  <div>
    <h1 
      className={`${transclcik === "trans"
      ? "border-l-4 pl-1  text-clicked sidecontent"
      : "text-slate-200 pl-1 sidecontent"
      }`}
    onClick={handleTransClicked}>
      Manage Transactions
    </h1>
  </div>
  </div>

          <div className="flex cursor-pointer justify-between items-center pb-5 pt-2  ">
            <div>
              <h1
               
                onClick={handleArrowClick}
              >
                Manage Memorials
              </h1>
            </div>
            <div >
            {arrowclicks ? <RiArrowDownSLine/> : <RiArrowRightSLine/>}
              
            </div>
          </div>
          {arrowclicks && (
          <div className="flex cursor-pointer flex-col">

              <>
                <span
                  onClick={handleFreePlan}
                  className={`${Fplan === "freeplan"
                    ? "border-l-4 pl-1  text-clicked sidecontent"
                    : "text-slate-200 pl-1 sidecontent"
                    }`}
                >
                  Free Memorials
                </span>

                <span
                  onClick={handleYearlyPlan}
                  className={`${Yplan === "yearlyplan"
                    ? "border-l-4 pl-1  text-clicked sidecontent"
                    : "text-slate-200 pl-1 sidecontent"
                    }`}
                >
                  Yearly Memorials
                </span>

                <span
                  onClick={handleLifePlan}
                  className={`${Lplan === "lifeplan"
                    ? "border-l-4 pl-1  text-clicked sidecontent"
                    : "text-slate-200 pl-1 sidecontent"
                    }`}
                >
                  Lifetime Memorials
                </span>
              </>
          </div>
           )}

          <div className="flex cursor-pointer justify-between items-center pb-5 pt-2  ">
            <div className="  ">
              <h1
                onClick={handleStoriesClicked}
              >
                Manage Stories
              </h1>
            </div>

            <div >
              {storiesclicked ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
            </div>
          </div>

          {storiesclicked && (
          <div className="flex cursor-pointer flex-col">
           
              <>
                <span
                  onClick={handleAllstories}
                  className={`${Astories === "allstories"
                    ? "border-l-4 pl-1  text-clicked sidecontent"
                    : "text-slate-200 pl-1 sidecontent"
                    }`}
                >
                  All Stories
                </span>

                <span
                  onClick={handleBannedstories}
                  className={`${Bstories === "bannedstories"
                    ? "border-l-4 pl-1  text-clicked sidecontent"
                    : "text-slate-200 pl-1 sidecontent"
                    }`}
                >
                  Banned Stories
                </span>
              </>
          </div>
          )}

          <div className="flex cursor-pointer justify-between items-center pb-5 pt-2  ">
            <div className="  ">
              <h1
                onClick={handleTributeClick}
              >
                Manage Tribute
              </h1>
            </div>

            <div onClick={() => navigate("/managetribute")}>
              {tributeclicked ? <RiArrowDownSLine /> :<RiArrowRightSLine /> }
            </div>
          </div>


          {tributeclicked && (
          <div className="flex cursor-pointer flex-col">
              <>
                <span
                  onClick={handleTributes}
                  className={`${Atributes === "alltributes"
                    ? "border-l-4 pl-1  text-clicked sidecontent"
                    : "text-slate-200 pl-1 sidecontent"
                    }`}
                >
                  All Tributes
                </span>

                <span
                  onClick={handleBannedtributes}
                  className={`${Btributes === "bannedtributes"
                    ? "border-l-4 pl-1  text-clicked sidecontent"
                    : "text-slate-200 pl-1 sidecontent"
                    }`}
                >
                  Banned Tributes
                </span>
              </>
          </div>
           )}
        
         

        <div className="flex cursor-pointer justify-between items-center pb-5 pt-2  ">
          <div className="  ">
            <h1
              onClick={handleImageClick}
            >
              Manage Images
            </h1>
          </div>

          <div>
            {imageclicked ? <RiArrowDownSLine />: <RiArrowRightSLine /> }
          </div>
        </div>
          
        {imageclicked &&(
        <div className="flex cursor-pointer flex-col">
            <>
              <span
                onClick={handleImages}
                className={`${Aimages === "allimages"
                  ? "border-l-4 pl-1  text-clicked sidecontent"
                  : "text-slate-200 pl-1 sidecontent"
                  }`}
              >
                All Images
              </span>

              <span
                onClick={handleBannedimages}
                className={`${Bimages === "bannedimages"
                  ? "border-l-4 pl-1  text-clicked sidecontent"
                  : "text-slate-200 pl-1 sidecontent"
                  }`}
              >
                Banned Images
              </span>
            </>
        
        </div>
        )}

<div className="flex cursor-pointer justify-between items-center pb-5 pt-2  ">
          <div className="  ">
            <h1
              onClick={handleAllusersclick}
            >
              Manage Users
            </h1>
          </div>

          <div>
            {usersClicked ?<RiArrowDownSLine /> : <RiArrowRightSLine /> }
          </div>
        </div>

        {usersClicked &&(
        <div className="flex cursor-pointer flex-col">
            <>
              <span
                onClick={handleAllusers}
                className={`${Ausers === "allusers" || Musers === "allusers"
                  ? "border-l-4 pl-1  text-clicked sidecontent"
                  : "text-slate-200 pl-1 sidecontent"
                  }`}
              >
                All Users
              </span>

              <span
                onClick={handleBannedUsers}
                className={`${Busers === "bannedusers"
                  ? "border-l-4 pl-1  text-clicked sidecontent"
                  : "text-slate-200 pl-1 sidecontent"
                  }`}
              >
                Banned Users
              </span>
            </>
        </div>
        )}

      
<div className="flex cursor-pointer justify-between items-center pb-5 pt-2">
  <div className="">
  <h1 
    className={`${alladmin === "admin"
    ? "border-l-4 pl-1  text-clicked sidecontent"
    : "text-slate-200 pl-1 sidecontent"
    }`}
  onClick={handleAllAdmin}>
      Manage Admins
    </h1>
  </div>
  </div>
     
  <div className="flex cursor-pointer justify-between items-center pb-5 pt-2">
  <div>
<h1 
className={`${
  (Aplans === 'allusers' || Mplans === 'allplans')
    ? 'border-l-4 pl-1 text-clicked sidecontent'
    : 'text-slate-200 pl-1 sidecontent'
}`}
onClick={handleAllplans}
>
      Manage Plans
    </h1>
  </div>
  </div>






        <div
          className="p-0 cursor-pointer mt-10 mb-5 cursor-pointer "
          
        >
          <h1
            style={{
              borderTop: "1px solid #D1CFD7",
            }}
            className="border-t pt-5"
          >
           <a href="/changepassword"> Change Password</a>
          </h1>
        </div>



        <div
          className="p-0 cursor-pointer mt-10 mb-5 cursor-pointer "
          onClick={handleClick}
        >
          <h1
            style={{
              borderTop: "1px solid #D1CFD7",
            }}
            className="border-t pt-5"
          >
            Logout
          </h1>
        </div>
      </div>



      <DialogClickout
        open={changepass}

      >


        <div className='Upgrade_enable' >
          {/* <div>
            <h1 className='aud_enb' >Change Password</h1>
            <span onClick={() => setChangePass(false)} >Close</span>
          </div> */}
          <div>
            <div style={{
              display: 'flex', justifyContent: 'space-between', marginBottom: '20px'
            }} >
              <div><h1 className='aud_enb' >Change Password</h1></div> &nbsp; &nbsp; &nbsp;
              <div>   <span className="close_btn" onClick={() => setChangePass(false)} >Close</span></div>
            </div>


          </div>

          <div>
            {/* <button className='aud_btn' onClick={SubmitPlan} >Ok</button> */}

            <form onSubmit={SubmitPlan} >

              <div className="form_content">
                <div className="form_input" >
                  <input type="password" value={currentp} onChange={(e) => setCurrentP(e.target.value)} required placeholder="current password" />
                </div>

                <div className="form_input" >
                  <input type="password" required value={newp} onChange={(e) => setNewP(e.target.value)} placeholder="new password" />
                </div>

                <div className="form_input" >
                  <input value={newpc} required onChange={(e) => setpc(e.target.value)} type="password" placeholder="Confirm Password" />
                </div>


                <div className="form_btn">
                  <input type="submit" value={submitting ? "Submitting" : "Submit"} />
                </div>
              </div>
            </form>

          </div>
        </div>

      </DialogClickout>
    </div>
    </div>
  );
}

export default Sidebar;
