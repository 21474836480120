import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'
import { Axios } from '../utils/Axios'

const deletesingleAdmin = async (id) => {
    const response = await AuthAxios?.get(`/admin/delete/${id}`)
    const data = response.data.data
    return data
  }



export default function useDeleteAdmin(id, onSuccess, onError) {

    const {deleteadmin, setDeleteAdmin} = useContext(DashboardContext);

      return useQuery('delete admin', ()=> deletesingleAdmin(id), {
        refetchOnWindowFocus: false ,
        enabled: false,

       
        onError: (error)=>{
            console.log(error)
        },
        
        onSuccess: (data, message) => {
            //  console.log(data, "deleted response")
             setDeleteAdmin(data);

          },


        //   onError: (error)=>{
        //     console.log(error, "error occured")
        //   }

          


      })
}
