import Input from '@mui/material/Input';
import React from 'react'
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF7900",
    },
  },
});

function InputField({ defaultValue, id, label, type,onChange, endAdornment, error  }) {
  return (
    <ThemeProvider theme={theme}>
    <>
        <FormControl variant="standard" fullWidth size='small'>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Input 
                style={{padding:'0 10px'}}
                type={type}
                id={id}
                endAdornment={endAdornment}
                error = {error}
                onChange = {onChange}
                defaultValue = {defaultValue}
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
            />
            <label className='error_label'>{error?.message}</label>
        </FormControl>
    </>
    </ThemeProvider>
  )
}

export default InputField
