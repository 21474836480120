import React from "react";
import Body from "./Body";
import "./Responsive.css";
import Sidebar from "./Sidebar";

function Responsive({ data }) {
  return (
    <div>
      <div className="relative min-h-screen md:flex" data-dev-hint="container">
        <input type="checkbox" id="menu-open" className="hidden" />

        <header
          className="bg-gray-600 text-gray-100 flex justify-between md:hidden"
          data-dev-hint="mobile menu bar"
        >
          <a
            href="#/"
            className="block p-4 text-white font-bold whitespace-nowrap truncate"
          >
            {/* Create Tribute */}
          </a>

          <label
            for="menu-open"
            id="mobile-menu-button"
            className="m-2 p-2 focus:outline-none hover:text-white hover:bg-gray-700 rounded-md"
          >
            <svg
              id="menu-open-icon"
              className="h-6 w-6 transition duration-200 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <svg
              id="menu-close-icon"
              className="h-6 w-6 transition duration-200 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </label>
        </header>

        <aside
          id="sidebar"
          className=" text-gray-100  space-y-6  px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
          data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation"
        >
          <Sidebar Homeclcik="home" />
        </aside>

        <div className=" bg-black  bgclass "></div>

        {/* <main
          id="content"
          className="flex-1 mt-8 md:mr-12 xl:mr-12 md:w-12 sm:mr-0    bg-sidebar  p-6 lg:px-8"
        > */}
        <main
          id="content"
          className="flex-1 mt-8  xl:mr-12 md:w-12 sm:mr-0    bg-sidebar   "
        >
          <div className=" mx-auto">{data}</div>
        </main>
      </div>
    </div>
  );
}

export default Responsive;
