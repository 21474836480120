import React from 'react'
import Createtributelogo from '../Assets/Createtributelogo.svg'
import profiletributeicon from '../Assets/profiletributeicon.svg'
import { useNavigate } from "react-router-dom";

function Navbar() {
  let navigate = useNavigate();
  return (
    <div>
      <nav className='flex flex-wrap justify-between items-center sm:items-center md:items-center sm:flex-wrap  bg-navbg pt-7 pb-5 pr-10 pl-10 shadow-bxshadow '   >

        <div className='flex items-center   sm:items-center md:items-center sm:flex-wrap'  >

          <div className='cursor-pointer' onClick={() => navigate('/dashboard')}   >
            <img src={Createtributelogo} alt="Createtributelogo" />
          </div>

          {/* <div>
            <input type="search" name="q" className="py-2 text-sm text-white bg-gray-900 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="Search for anything" autoComplete="off" />
          </div> */}
        </div>
        <div className="">
          <img src={profiletributeicon} alt="profiletributeicon" />
        </div>


      </nav>



    </div>
  )
}

export default Navbar