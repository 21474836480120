import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext';
import AuthAxios from '../utils/AuthAxios';
import { Axios } from '../utils/Axios'

export default function useTransaction() {

    const {  settransdetails} = useContext(DashboardContext);
    const fetchTransaction = async (currency) => {
        const response = await AuthAxios.get(`/transaction`)
        const data = response.data.data
        return data
      }

      return useQuery('fetch countries', fetchTransaction, {
         refetchOnWindowFocus: false,
          onSuccess: (data) => {
            //  console.log(data, "transaction data")
             settransdetails(data);
          },
      })
}
