import { useContext } from "react";
import { useQuery } from "react-query";
import { DashboardContext } from "../Context/DashboardContext";
import AuthAxios from "../utils/AuthAxios";

const getfilterdata = async (plan, date) => {
    const response = await AuthAxios.get(`/memorial/${plan}/filter/${date}`);
    const data = response;
    return data;
};

export const useFilterMemorial = (date, plan, Success, error,) => {
    const { filterdataplan, setfilterdataplan } = useContext(DashboardContext);

    return useQuery("filter memorial data", () => getfilterdata(plan, date), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            // setfilterdata(data?.data?.data);
            // console.log(data?.data?.data, 'true');
            setfilterdataplan(data?.data?.data)
        },
        onError: error
    });
};




