import React, { forwardRef, useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import calender from "../../Assets/calendar.svg";
import { useTransdelete } from "../../api/useTransdelete";
import DatePicker from "react-datepicker";
import { TbLineDotted } from "react-icons/tb";
import exporticon from "../../Assets/exporticon.svg";
import { jsPDF } from "jspdf";
import { useFilter } from "../../api/useFilter";
import moment from "moment";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { DashboardContext } from "../../Context/DashboardContext";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import useFreePlan from "../../api/useFreePlan";

import Dropdown from "../../Components/Dropdown";
import deletesvg from "../../Assets/deletesvg.svg";
import useView from "../../api/useView";
import autoTable from 'jspdf-autotable';
// import useMemorialdelete, { useDeleteMemorial } from "../../api/useDeleteMemorial";
// import { Local_storage } from "../../utils/LocalStorageConfig";
// import axios from "axios";
import { useFilterMemorial } from "../../api/useFiltermemorial";
import useSuspendMemorial from "../../api/useSuspendMemorial";
import { useDeleteMemorial } from "../../api/useDeleteMemorial";
import { useNavigate } from "react-router-dom";
import DialogClickout from "../../Components/DialogClickout";
import '../../Styles/MemorialPage.css'
import { number } from "yup";
import { Local_storage } from "../../utils/LocalStorageConfig";


function MemorialPage() {
  let navigate = useNavigate();
  const defaultMaterialTheme = createTheme();

  const [startDate, setStartDate] = useState(new Date());

  const [idv, setidv] = useState('')

  const [filterclick, setfilterclick] = useState(false)

  const [planid, setplanId] = useState("")



  const {
    UpdateMenuClicked,

    MoreInfodata,
    transdetails,
    infodata,
    filterdataplan, setfilterdataplan,
    settransindividual, suspendmemorial, setSuspendMemorial
  } = useContext(DashboardContext);



  const { refetch, isLoading, data } = useFreePlan();

  // const { refetch: isrefetch, isSuccess,
  //   isLoading: Load,
  //   data: isdata, } = useFilterMemorial(1,
  //     moment(startDate)?.format("YYYY-MM-D")
  //   );

  const { refetch: isrefetch, isSuccess,
    isLoading: Load,
    data: isdata, } = useFilterMemorial(moment(startDate)?.format("YYYY-MM-D"), 1);

  const { refetch: refetchview, isSuccess: viewisSuccess, data: viewdata } = useView(infodata?.id);

  const { refetch: refetchdelete } = useView(infodata?.id);


  // const { refetch: issuspendrefetch, isissuspendSuccess,
  //   isLoading: issuspendLoad,
  //   data: issuspendisdata, } = useSuspendMemorial(infodata?.uuid);

  const onSuccess = (item) => {
    // refetch();
    isrefetch()
  };

  const handleViewClick = (data, type) => {

    refetchview();

    if (viewisSuccess) {

      refetchview()

    }

  };

  const handleDeleteUser = (data, type) => {
    // refetchdelete();
    // console.log(data);
    const id = data.id

    // console.log(id, 'idddd')

    const data_ = { memorial_ids: [id] };

    deleteUser(data_);
  };

  const handleSuspendMemorial = (data, type) => {
    // console.log(data, 'data')
    // issuspendrefetch()
    // if (isSuccessdeletememorial) {
    //   refetch()
    //   isrefetch()
    // }

    var myHeaders = new Headers();
    myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${Local_storage().get("_utk")}`);

    // var formdata = new FormData();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      // body: formdata,
      redirect: 'follow'
    };

    fetch(`https://api.createtribute.com/admin/memorial/suspend/${infodata?.uuid}`, requestOptions)
      .then(response => response.json())
      .then((res) => {
        console.log(res, 'res')
        window.location.reload()
      })
      .catch(error => console.log('error', error));




  }

  const handleUpdateMemorial = (data, type) => {
    console.log(data, 'data UpdateMemorial')
    navigate('/updatememorial', {
      state: data
    })

  }


  const handleUpgradeMemorial = (data, type) => {
    console.log(data, 'data UpdateMemorial')
    setClick(true)
    // navigate('/updatememorial', {
    //   state: data
    // })

  }


  const onError = (error) => { };


  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    { title: "Website", field: "slug" },
    { title: "Created By", field: "created_by" },
    {
      title: "Created On",
      field: "created_on",

      render: (rowData) => (
        <div>
          <p>{moment(rowData?.created_on)?.format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Status",
      field: "status",

      render: (rowData) => (
        <div className="paragraphp">
          <div className="beforep">
            <p>
              {rowData.status === "active" ? (
                <span className="activestatus"> {rowData.status}</span>
              ) : rowData.status === "unverified" ? (
                <span className="unverified"> {rowData.status}</span>
              ) : rowData.status === "banned" ? (
                <span className="banned"> {rowData.status}</span>
              ) : (
                <span className="unknown"> {rowData.status}</span>
              )}
            </p>
          </div>
        </div>
      ),
    },

    {
      title: "Action",
      field: "more",

      render: (rowData) => (
        <div>
          <Dropdown
            editClick={() => handleViewClick(infodata, "Tribute")}
            deleteClick={() => handleDeleteUser(infodata, "Tribute")}
            SuspendClick={() => handleSuspendMemorial(rowData)}
            UpdateClick={() => handleUpdateMemorial(rowData)}
            UpgradeClick={() => handleUpgradeMemorial(rowData)}
            suspendprop
            updatememorial
            upgrade
          />
        </div>
      ),
    },
  ];





  const doc = new jsPDF()


  const handleExport = () => {



    const pdf = new jsPDF("p", "mm", "a4");

    pdf.text(105, 10, "Transaction details", null, null, "center");
    pdf.text(10, 20, "Manage transaction");




    autoTable(doc, {
      head: [[
        // "TableData",
        "id",
        "Slug",
        "Created_by",
        "Created_on",
        "Status",
      ]],
      body:

        data?.map((x, i) => {
          return [
            x?.id,
            x?.slug,
            x?.created_by,
            x?.created_on,
            x?.status,
          ];
        }),




    })

    doc.save('table.pdf')
  };


  const { mutate: deleteUser,
    refetch: refetchmemorialstory,
    isSuccess: isSuccessdeletememorial,
    isLoading: isloadingdeletememorial,
    data: datadeletememorial,
  } = useDeleteMemorial(onSuccess, onError);




  const handleBulkDelete = (rowData) => {
    // console.log(infodata, 'infodataaa')

    // console.log(idv)
    const id = rowData.map((x) => x?.id);

    // console.log(id, 'idddd')

    const data = { memorial_ids: id };

    deleteUser(data);



  };

  const HandleFilter = () => {
    return (

      console.log("to use"),
      setfilterclick(!filterclick),
      isrefetch(),
      console.log(filterdataplan, 'filterdataplan')
    );
  };








  const selectedRow = React.useRef([]);

  const handleClick = rows => { selectedRow.current = rows; };

  const handleClickB = (rows) => {

    setidv(selectedRow.current = rows)

  }


  const [click, setClick] = useState(false);


  const handleChange = (e) => {
    setplanId(e.target.value)
  }


  const SubmitPlan = (e) => {
    e.preventDefault()
    setClick(false)
    console.log(infodata?.uuid)
    console.log(planid, 'planidddd')




    var myHeaders = new Headers();
    myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${Local_storage()?.get('_utk')}`);


    var formdata = new FormData();
    formdata.append("plan_id", Number(planid));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`https://api.createtribute.com/admin/memorial/plan/upgrade/${infodata?.uuid}`, requestOptions)
      .then(response => response.json())
      .then((res) => {
        console.log(res)
        setplanId("")
        if (res?.status === true) {
          window.location.reload()
        }
      })
      .catch((err) => {
        console.log(err, "err")
        console.log(err.response, 'responseee')
      });



  }


  useEffect(() => {

    if (isSuccessdeletememorial) {
      refetch()
      isrefetch()
    }

  }, [isSuccessdeletememorial, refetch, isrefetch]);


  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Navbar />
          {/* <ReactQueryDevtools initialIsOpen={false}  /> */}
          <div>
            <div
              className="relative min-h-screen md:flex"
              data-dev-hint="container"
            >
              <input type="checkbox" id="menu-open" className="hidden" />

              <header
                className="bg-gray-600 text-gray-100 flex justify-between md:hidden"
                data-dev-hint="mobile menu bar"
              >
                <a
                  href="#/"
                  className="block p-4 text-white font-bold whitespace-nowrap truncate"
                >

                </a>

                <label
                  htmlFor="menu-open"
                  id="mobile-menu-button"
                  className="m-2 p-2 focus:outline-none hover:text-white hover:bg-gray-700 rounded-md"
                >
                  <svg
                    id="menu-open-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    id="menu-close-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </label>
              </header>

              <aside
                id="sidebar"
                className=" zindexproperty text-gray-100     space-y-6  px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
                data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation"
              >
                <Sidebar arrowclick="memorial" />
              </aside>

              <div className=" bg-black  bgclass "></div>

              <main
                id="content"
                className="flex-1 mt-8  xl:mr-12 md:w-12 sm:mr-0    bg-sidebar   "
              >
                <div className="mx-auto">
                  {isLoading ? (
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 2,
                      }}
                      open={isLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    <div className="pt-7">
                      <div></div>

                      <ThemeProvider theme={defaultMaterialTheme}>
                        <div>
                          <div className=" shadow-bxshadow-container   ">
                            <h1 className="font-inter pr-10 pl-10   not-italic pb-5 boldtitle  ">
                              Manage Memorials
                            </h1>
                          </div>

                          <div>
                            <div className="pr-10 pl-10 pt-8  ">
                              <h1 className="analytics">FREE PLAN</h1>
                            </div>
                          </div>

                          <div style={{ maxWidth: "100%" }}>
                            <div
                              style={{
                                marginBottom: "-50px",
                                zIndex: "2000",
                                position: "relative",
                              }}
                            >
                              <div className="pr-10 pl-10 pt-8">
                                <div className="flex justify-between ">
                                  <div className="flex justify-center flex-wrap  toflexmobile  items-center gap-2 ">
                                    <div
                                      onClick={HandleFilter}
                                      className="dottedbody flex items-center cursor-pointer  "
                                    >
                                      <span>Filter</span>
                                    </div>

                                    <div
                                      style={{ position: "relative" }}
                                      className="flex items-center cursor-pointer "
                                    >
                                      <div>
                                        <DatePicker
                                          showDayMonthYearPicker
                                          selected={startDate}
                                          onChange={(date) => {
                                            return setStartDate(date);
                                          }}
                                          className="date-picker"
                                        />
                                      </div>

                                      <div
                                        style={{
                                          position: "relative",
                                          right: "45px",
                                        }}
                                        className=""
                                      >
                                        <img src={calender} alt="calender" />
                                      </div>
                                    </div>

                                    <div className="dottedbody">
                                      <TbLineDotted size={32} color="#FF7900" />
                                    </div>

                                    <div
                                      onClick={handleExport}
                                      className="dottedbody flex items-center cursor-pointer  "
                                    >
                                      <div className=" pt-2  pb-2 pr-1">
                                        {" "}
                                        <img
                                          src={exporticon}
                                          alt="exporticon"
                                        />{" "}
                                      </div>
                                      <div>Export</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {isLoading ? (
                              <p>Loadingdata...</p>
                            ) : (
                              <div className="toptobottom ">
                                <MaterialTable
                                  icons={tableIcons}


                                  // data={data?.map((v) => ({
                                  //   ...v,
                                  //   more: "...",
                                  // }))}


                                  data={
                                    filterclick ? isdata?.data?.data?.map((v) => ({
                                      ...v,
                                      more: "...",
                                    })) : data?.map((v) => ({
                                      ...v,
                                      more: "...",
                                    }))
                                  }









                                  // onSelectionChange={(rows) => {
                                  //   return (
                                  //      console.log(rows, 'rowsss'),
                                  //      setidv(rows),
                                  //       MoreInfodata(rows)
                                  //   )
                                  // }


                                  //     =>
                                  // console.log(rows, 'rowsss') setidv(rows)
                                  //  MoreInfodata(rows)

                                  //  }



                                  // onSelectionChange={(e) => { handleClickB(e) }}


                                  columns={columns}
                                  options={{
                                    selection: true,
                                    showTitle: false,
                                    showEmptyDataSourceMessage: false,
                                    pageSizeOptions: [20, 50],
                                    paging: true,
                                    emptyRowsWhenPaging: false,
                                    pageSize: 20,

                                    showTextRowsSelected: false,
                                    actionsColumnIndex: -1,

                                  }}
                                  actions={[
                                    {
                                      icon: () => (
                                        <img src={deletesvg} alt="deleteicon" />
                                      ),

                                      onClick: (event, rowData) => {
                                        handleBulkDelete(rowData);
                                        setidv(rowData.map((x) => x?.id))
                                        MoreInfodata(rowData)
                                      },
                                    },
                                  ]}
                                  localization={{
                                    pagination: {
                                      nextAriaLabel: "Next Page",
                                      labelRowsPerPage: " ",
                                    },
                                  }}
                                  onRowClick={(event, rowData, togglePanel) => {
                                    return (
                                      UpdateMenuClicked("moredetails"),
                                      MoreInfodata(rowData),
                                      settransindividual(rowData)
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </ThemeProvider>
                    </div>
                  )}
                </div>
              </main>
            </div>


            <DialogClickout
              open={click}

            >


              <div className='Upgrade_enable' >
                {/* <div>
                  <h1 className='aud_enb' >Upgrade Plan</h1>

                  <span onClick={() => setClick(false)} >Close</span>
                </div> */}
                <div>
                  <div style={{
                    display: 'flex', justifyContent: 'space-between', marginBottom: '20px'
                  }} >
                    <div><h1 className='aud_enb' >Upgrade Plan</h1></div> &nbsp; &nbsp; &nbsp;
                    <div>   <span className="close_btn" onClick={() => setClick(false)} >Close</span></div>
                  </div>


                </div>

                <div>
                  {/* <button className='aud_btn' onClick={SubmitPlan} >Ok</button> */}

                  <form onSubmit={SubmitPlan} >

                    <div className="form_content">
                      <div className="form_input" >
                        <input value={planid} type="number" onChange={handleChange} placeholder="planid" />
                      </div>


                      <div className="form_btn">
                        <input type="submit" value="Submit" />
                      </div>
                    </div>
                  </form>

                </div>
              </div>

            </DialogClickout>
          </div>
        </>
      )}
    </>
  );
}

export default MemorialPage;
