import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchAllUsers = async (id) => {
    const response = await AuthAxios?.get(`/user`)
    const data = response.data.data
    return data
  }



export default function useAllUsers(id, onSuccess, onError) {

    const {allusers, setAllusers} = useContext(DashboardContext);
      return useQuery('fetch all admin', ()=>fetchAllUsers(), {
        refetchOnWindowFocus: false ,
        onSuccess: (data) => {
            //  console.log(data, "individual data")
           
             setAllusers(data);
        
           
            
          },
       
        onError: (onError=>{


        })
      })
}
