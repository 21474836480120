import { QueryClientProvider,  QueryClient  } from "react-query";
import DashProvider from "./Context/DashboardContext";
import Routing from "./Routing";
import {ReactQueryDevtools} from 'react-query-devtools'

const query_client = new QueryClient()


function App() {
  return (
    <div className="bg-navbg " >
      
        <QueryClientProvider client={query_client}  >

        <DashProvider>
         <Routing />
         </DashProvider>
         <ReactQueryDevtools />
         </QueryClientProvider>


    
    </div>
  );
}

export default App;
