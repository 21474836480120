import { useContext } from "react";
import { useQuery } from "react-query";
import { DashboardContext } from "../Context/DashboardContext";
import AuthAxios from "../utils/AuthAxios";

const getLogout = async () => {
  const response = await AuthAxios.get(`/logout`);
  const data = response;
  return data;
};

export const useGetLogoutData = () => {
  const { LogoutFunction, logout, setLogout } = useContext(DashboardContext);

  return useQuery("fetch dash data ", () => getLogout(), {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      LogoutFunction(data);
    },
  });
};
