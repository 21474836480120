/* eslint-disable jsx-a11y/role-supports-aria-props */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moreicon from "../Assets/moreicon.svg";
import moredetailsicon from "../Assets/moredetailsicon.svg";
import { useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function DropdownImages({banClick, deleteClick, deleteuser, hideClick }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    // console.log(data);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{position:'relative'}}>
   <button  style={{padding:"4px",paddingLeft:"8px",backgroundColor:"#FD7902", borderRadius:"4px"}} onClick={handleClick}>
       <p style={{color:"#F5F5F5"}}> View <ArrowDropDownIcon style={{color:"#F5F5F5"}}/></p> 
      </button>
        
  <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
  >

    <MenuItem onClick={()=>{handleClose(); deleteClick()}}>Delete</MenuItem>
    <MenuItem onClick={()=>{handleClose(); banClick()}}>Ban</MenuItem>
    {/* <MenuItem onClick={()=>{handleClose(); hideClick()}}>Hide from public</MenuItem> */}
  </Menu>
</div>
  )
}

export default DropdownImages