/* eslint-disable jsx-a11y/role-supports-aria-props */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moreicon from "../Assets/moreicon.svg";
import moredetailsicon from "../Assets/moredetailsicon.svg";
import { useEffect } from 'react';

function DropdownStory({banClick, deleteClick, deleteuser, hideClick , propone , proptwo}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    // console.log(data);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{position:'relative'}}>
    <img src={window.location.pathname === '/transact' ? moredetailsicon  : moreicon   } alt='dots' id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size={24}
    />
  <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
  >

    <MenuItem onClick={()=>{handleClose(); deleteClick()}}> {propone ? propone : "Delete"}</MenuItem>
    <MenuItem onClick={()=>{handleClose(); banClick()}}>{proptwo ? proptwo : "Ban"}</MenuItem>
    {/* <MenuItem onClick={()=>{handleClose(); hideClick()}}>Hide from public</MenuItem> */}
  </Menu>
</div>
  )
}

export default DropdownStory