import React, { useContext } from 'react'
import { useMutation } from 'react-query';
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const deletememorial = async (id) => {
    const response = await AuthAxios?.post(`/memorial/delete`, id)
    const data = response.data.data
    return data
}



export const useDeleteMemorial = (onSuccess, onError) => {
    return useMutation(deletememorial, {
        onError: onError,
        onSuccess: onSuccess
    })

}

// export default function useMemorialdelete(id) {

//     // const { deletestory, setDeleteStory, } = useContext(DashboardContext);


//     return useQuery(['delete memorial', { id }], () => deletememorial(id), {
//         refetchOnWindowFocus: false,
//         enabled: false,

//         onSuccess: (data, message) => {
//             console.log(data, "deleted response")
//             // setDeleteStory(data);
//         },


//         onError: (error) => {
//             // console.log(error, "error occured")
//         }

//     })



//     // return useQuery('delete memorial', () => deletememorial(id), {
//     //     refetchOnWindowFocus: false,
//     //     enabled: false,

//     //     onSuccess: (data, message) => {
//     //         //  console.log(data, "deleted response")
//     //         // setDeleteStory(data);
//     //     },


//     //     onError: (error) => {
//     //         // console.log(error, "error occured")
//     //     }

//     // })
// }


