import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import { Dialog, DialogActions, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom'; // Import Link from React Router

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4, 4, 4),
      borderRadius: '8px',
      outline: 'none', // Remove default outline
    },
    button: {
      width: '100px',
      height: '40px',
      margin: theme.spacing(1),
      borderRadius: '4px',
      '&.yesButton': {
        backgroundColor: 'gray',
        color: 'white',
      },
      '&.noButton': {
        backgroundColor: 'orange',
        color: 'white',
      },
    },
  }));

export default function DropdownAdmin({ deleteClick }) {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const open = Boolean(anchorEl);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmAction = () => {
    deleteClick(); // Perform delete action here

    setOpenModal(false);
  };

  const handleCancelAction = () => {
    setOpenModal(false);
  };

  const handleActionClick = () => {
    setSelectedAction('delete'); // Set selected action as delete
    setOpenModal(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <button
          style={{
            padding: '4px',
            paddingLeft: '8px',
            backgroundColor: '#FD7902',
            borderRadius: '4px',
          }}
          onClick={handleClick}
        >
          <p style={{ color: '#F5F5F5' }}>
            {' '}
            View <ArrowDropDownIcon style={{ color: '#F5F5F5' }} />
          </p>
        </button>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { handleClose(); handleActionClick('delete') }}>
          Delete
        </MenuItem>
        {/* Add Admin Button Linking to /blog */}
        <MenuItem component={Link} to="#" onClick={handleClose}>
          Edit
        </MenuItem>
      </Menu>
      <Dialog open={openModal} onClose={handleCancelAction} className={styles.modal}>
        <div className={styles.paper}>
          <p>{`Do you want to ${selectedAction}?`}</p>
          <DialogActions>
            <Button className={`${styles.button} yesButton`} onClick={handleConfirmAction}>
              Yes
            </Button>
            <Button className={`${styles.button} noButton`} onClick={handleCancelAction}>
              No
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
