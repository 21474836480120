import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchFreePlan = async (id) => {
    const response = await AuthAxios?.get(`/memorial/${1}`)
    const data = response.data.data
    return data
  }



export default function useFreePlan(id) {

    const {freeplan, setfreeplan} = useContext(DashboardContext);
      return useQuery('fetch free plan', ()=>fetchFreePlan(id),
      
      
      {
        refetchOnWindowFocus: false ,
        onSuccess: (data) => {
            // console.log(data, "individual data")
           
            setfreeplan(data);
        
           
            
          },
          
      }
      
      )
}
