import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchAllAdmin = async (id) => {
    const response = await AuthAxios?.get(`/admin`)
    const data = response.data.data
    return data
  }



export default function useAllAdmin(id, onSuccess, onError) {

    const {alladmin, setalladmin} = useContext(DashboardContext);
      return useQuery('fetch all admin', ()=>fetchAllAdmin(id), {
        refetchOnWindowFocus: false ,
        // onSuccess: (data) => {
        //      console.log(data, "individual data")
           
        //     setalladmin(data);
        
           
            
        //   },
        onSuccess: onSuccess,
        onError: onError
      })
}
