import React from "react";
import Body from "../Components/Body";
import Navbar from "../Components/Navbar";
import Responsive from "../Components/Responsive";
import Sidebar from "../Components/Sidebar";

function Layout() {
  return (
    <div>
      <body>
        <Navbar />
        <Responsive data={<Body />} />
      </body>
    </div>
  );
}

export default Layout;
