import { useMutation } from 'react-query';
import AuthAxios from '../utils/AuthAxios';
import { Axios } from '../utils/Axios';

const useLoginUser= (user) => {
  return AuthAxios.post(`/transaction/delete`, user)
}


export const useTransdelete = (onSuccess, onError) =>{
  return useMutation(useLoginUser, {
      onError: onError,
      onSuccess: onSuccess
  })
}
