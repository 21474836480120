import React, { useContext, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { curveCardinal } from "d3-shape";
import { DashboardContext } from "../Context/DashboardContext";


function Chart({data_}) {
  const {UpdateAnalytics, analytics, setanalytics, analytichistory, setanalytichistory  , PaymentData, paymentinfo, setpaymentinfo   } = useContext(DashboardContext)


  // useEffect(() => {
  //  console.log(paymentinfo?.data?.data?.current_month_revenue)

  // }, []);




  const data = [
    {
      name: "Jan",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Apr",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "June",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Jul",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      uv: 490,
      pv: 4300,
      amt: 2100,
    },

    {
      name: "Sep",
      uv: 1490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Oct",
      uv: 890,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Nov",
      uv: 490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Dec",
      uv: 3290,
      pv: 4300,
      amt: 2100,
    },
  ];


  const cardinal = curveCardinal.tension(0.2);
  return (
    <div>
      <ResponsiveContainer width="90%" height={300}>
    
        <AreaChart
          width={650}
          height={250}
          data={paymentinfo?.data?.data?.current_month_revenue}
          margin={{top: 10, right: 30, left: 20,bottom: 0}}
        >
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#FF7900" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#FF7900" stopOpacity={0}/>
                </linearGradient>
            </defs>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="date"/>
          <YAxis
            label={{ value: 'Amount', angle: -90, position: 'left' }} 
          />
          <Tooltip/>
          <Area type="monotone" dataKey="amount" strokeWidth={3} stroke="#FF7900" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
 
      </ResponsiveContainer>
    </div>
  );
}

export default Chart;
