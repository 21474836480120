import React, { useEffect, useState } from "react";
import {
    AiFillAudio,
    AiFillPauseCircle,
    AiFillPlayCircle,
    AiOutlineClose,
    AiOutlineCloseCircle,
    AiOutlineDelete,
    AiOutlineMenu,
} from "react-icons/ai";
import "../../src/Styles/UpdateMemorial.css";
import { MdVisibility } from "react-icons/md";
import { Grid } from "@mui/material";
import PrimaryBtn from "../Components/Buttons/PrimaryBtn";
import ShadowCard from "../Components/ViewBody/ShadowCard";
import Copy from "../Components/Copy";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import Wysiwug from "../Components/Wisywug.jsx";
import IconButton from "../Components/IconButton.jsx";
import { FiEdit3, FiPlus } from "react-icons/fi";
import imageCompression from "browser-image-compression";
import { GrInstagram } from "react-icons/gr";
import { BsTwitter, BsWhatsapp } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthAxios from "../../src/utils/AuthAxios";
import { ContentState, convertFromHTML } from "draft-js";
import moment from "moment";
import { BiLink } from "react-icons/bi";
import $ from "jquery";

import {
    // convertFromHTML,
    convertToHTML,
} from "draft-convert";
import BackDrop from "../Components/BackDrop.jsx";
import {
    FacebookShareButton,
    InstapaperShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { useRef } from "react";
import { ScaleLoader } from "react-spinners";
import { Local_storage } from "../../src/utils/LocalStorageConfig";
import Navbar from "../Components/Navbar";

var a;
const today = moment().format("YYYY-MM-DD");

function UpdateMemorial() {
    var retrievedObject = localStorage.getItem("testObject");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    $(".open-datetimepicker").click(function (event) {
        event.preventDefault();
        $("#datetimepicker").click();
    });
    let location = useLocation();

    const audioRef = useRef();

    const [buttonName, setButtonName] = useState("Play");

    const [duration, setDuration] = useState(0);
    const [audio_file_name, setaudio_file_name] = useState("");
    const [audio_to_send, setaudio_to_send] = useState(null);
    const [audio, setAudio] = useState("");
    const [audioFromBE, setAudioFromBE] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [updatem, setupdatingm] = useState(false)

    useEffect(() => {
        if (a) {
            a.pause();
            a = null;
            setButtonName("Play");
        }
        if (audio) {
            a = new Audio(audio);
            a.onended = () => {
                setButtonName("Play");
            };
        }
    }, [audio]);

    const handleClick = () => {
        if (buttonName === "Play") {
            a.play();
            setButtonName("Pause");
        } else {
            a.pause();
            setButtonName("Play");
        }
    };

    function getDuration(src, cb) {
        var audio = new Audio();
        $(audio).on("loadedmetadata", function () {
            cb(audio.duration);
        });
        audio.src = src;
    }

    const addFile = (e) => {
        if (e.target.files[0]) {
            setaudio_file_name(e.target.files[0].name);
            setaudio_to_send(e.target.files[0]);
            setAudio(URL.createObjectURL(e.target.files[0]));
            getDuration(URL.createObjectURL(e.target.files[0]), function (length) {
                setDuration(length);
            });
        }
    };

    const navigate = useNavigate();

    const { slug } = useParams();

    const [updating, setupdating] = useState(false);
    const [saving, setsaving] = useState(false);

    var host = window.location.protocol + "//" + window.location.host;

    const link_to_share = `${host}/memorial/${slug}`;
    const preview_link = `${host}/preview_memo/${slug}`;

    const [user_memorial, setUser_memorial] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(false);
        // if(user_memorial === null){

        // console.log(slug, "sluggggggg");
        // setLoading(true);
        // AuthAxios.get(`/memorial/${slug}`)
        //     .then((response) => {
        //         updatePage(response.data);
        //     })
        //     .catch((err) => {
        //         setError(err);
        //         console.log(err);
        //     })
        //     .finally(() => {
        //         setLoading(false);
        //     });
        // }
    }, [slug]);

    // const { loading, user_memorial, error, refetch } = useSingleUserMemo(`/memorial/${slug}`)
    const [fullname, setfullname] = useState("");
    const [email, setemail] = useState("");
    const [valueBiography, setvalueBiography] = useState();
    const [valueHistory, setvalueHistory] = useState();
    const [galleries_from_BE, setGalleries_from_BE] = useState([]);
    const [galleries_to_BE, setGalleries_to_BE] = useState([]);
    const [galleries, setGalleries] = useState([]);
    const [showImageSpace, setShowImageSpace] = useState(false);
    const [stories, setStories] = useState([]);
    const [stories_from_BE, setStories_from_BE] = useState([]);
    const [showStory, setshowStory] = useState(false);
    const [type, settype] = useState("public");
    const [created_expire, setcreated_expire] = useState([]);
    const [dob, setdob] = useState("");
    const [dod, setdod] = useState("");
    const [decease_image, setdecease_image] = useState("");
    const [decease_image_to_send, setdecease_image_to_send] = useState(null);
    const [activities, setactivities] = useState([]);
    const [plan_type, setPlan_type] = useState("");
    const [status, setStatus] = useState(false);

    const [isActive, setActive] = useState(false);

    const [story_text, setstory_text] = useState("");
    const [story_image, setstory_image] = useState(null);


    const { register: registerStoryAttachment, watch: watchAttachment } =
        useForm();

    useEffect(() => {
        const subscription = watchAttachment((data) => {
            attachImage(data.attach_image);
        });
        return () => subscription.unsubscribe();
    }, [watchAttachment]);

    const attachImage = (file) => {
        if (file && file[0]) {
            setstory_image(file[0]);
            // var options = { maxSizeMB: 3,  maxWidthOrHeight: 512};
            // const output = await imageCompression(file[0], options);
            // const dataa = await imageCompression.getDataUrlFromFile(output);
        }
    };

    const { register, watch } = useForm();

    useEffect(() => {
        const subscription = watch((data) => {
            addDeceaseImage(data.decease_img);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const addDeceaseImage = async (file) => {
        if (file && file[0]) {
            setdecease_image_to_send(file[0]);

            var options = { maxSizeMB: 3, maxWidthOrHeight: 512 };
            const output = await imageCompression(file[0], options);
            const dataa = await imageCompression.getDataUrlFromFile(output);
            setdecease_image(dataa);

            setShowImageSpace(true);
        }
    };

    const { state } = useLocation();

    useEffect(() => {
        console.log(state, 'state')

    }, [state]);







    const UpdateMemo = (arg) => {
        setupdatingm(true)


        if (valueBiography?.getCurrentContent() === undefined || valueBiography?.getCurrentContent() === null || convertToHTML(valueBiography?.getCurrentContent()) === "<p></p>") {
            setupdatingm(false)
            return Swal.fire({
                icon: "error",
                iconColor: "#FF7900",
                text: `Short biography is required`,
                confirmButtonColor: "#FF7900",
                timer: 3000,
            });

        }
        if (valueHistory?.getCurrentContent() === undefined || valueHistory?.getCurrentContent() === null || convertToHTML(valueHistory?.getCurrentContent()) === "<p></p>") {
            setupdatingm(false)
            return Swal.fire({
                icon: "error",
                iconColor: "#FF7900",
                text: `Life history is required`,
                confirmButtonColor: "#FF7900",
                timer: 3000,
            });
        }

        if (fullname === "" || fullname === undefined || fullname === null) {
            setupdatingm(false)
            return Swal.fire({
                icon: "error",
                iconColor: "#FF7900",
                text: `Full name is required`,
                confirmButtonColor: "#FF7900",
                timer: 3000,
            });
        }



        // var formdata = new FormData();
        // var audioformdata = new FormData();


        // const JSBlogPost = JSON.stringify(
        //     convertToRaw(valueBiography.getCurrentContent())
        // );

        // formdata.append("fullname", fullname);
        // formdata.append("biography", JSON.stringify(convertToRaw(valueBiography.getCurrentContent())))

        // formdata.append(
        //     "life_history",
        //     JSON.stringify(convertToRaw(valueHistory.getCurrentContent()))
        // );
        // formdata.append("type", type);

        var myHeaders = new Headers();
        myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${Local_storage().get('_utk')}`);

        var formdata = new FormData();
        formdata.append("fullname", fullname);
        formdata.append("biography", JSON.stringify(convertToRaw(valueBiography.getCurrentContent())));
        formdata.append("life_history", JSON.stringify(convertToRaw(valueHistory.getCurrentContent())));
        formdata.append("type", type);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`https://api.createtribute.com/admin/memorial/update/${state?.uuid}`, requestOptions)
            .then(response => response.json())
            .then((res) => {

                if (res?.message === "Memorial updated successfully") {
                    setupdatingm(false)
                    Swal.fire({
                        icon: "success",
                        iconColor: "white",
                        text: `${res?.message}`,
                        confirmButtonColor: "#FF7900",
                        // timer: 3000,
                        showConfirmButton: true
                    }).then(() => {
                        navigate('/managememorial')
                    });
                }

                console.log(res, 'res')

            })
            .catch((err) => {
                setupdatingm(false)
                Swal.fire({
                    icon: "error",
                    iconColor: "red",
                    text: `${err?.message}`,
                    confirmButtonColor: "white",
                    // timer: 3000,
                    showConfirmButton: true
                }).then(() => {
                    navigate('/managememorial')
                });
            });

    };







    if (loading) {
        return <BackDrop open={loading} />;
    }

    return (
        <>
            <Navbar />
            <div className="upgrademain_container" >


                <div className="grid-container">
                    <section className="main">
                        {/* <h3 className='publish_title'>Upgrade Memorial</h3> */}
                        <div className="edit_body">
                            <Grid container spacing={{ xs: 3, md: 4 }}>
                                <Grid item xs={12} md={12}>
                                    <div className="titl_s" onClick={() => navigate(-1)}  >Go Back</div>
                                    <ShadowCard>
                                        <input
                                            className="input_shadow"
                                            type="text"
                                            onChange={(e) => setfullname(e.target.value)}
                                            value={fullname}
                                            // value=""
                                            placeholder="Full Name"
                                            required
                                        />
                                    </ShadowCard>

                                    <div className="permalinkss">
                                        {/* <span>Permalink: </span>
                                <Copy email={link_to_share} /> */}
                                    </div>
                                    <div className="editor_container">
                                        <div>
                                            <div className="titl_h">About</div>
                                            <div className="titl_s">Create a short biography</div>
                                            <ShadowCard>
                                                <Wysiwug
                                                    valueState={valueBiography}
                                                    setvalueState={setvalueBiography}
                                                />
                                            </ShadowCard>
                                        </div>
                                        <div>
                                            <div className="titl_h">Life History</div>
                                            <div className="titl_s">Create detailed life history</div>
                                            <ShadowCard>
                                                <Wysiwug
                                                    valueState={valueHistory}
                                                    setvalueState={setvalueHistory}
                                                />
                                            </ShadowCard>
                                        </div>


                                        <ShadowCard>
                                            <div className="public_contai">

                                                <div className="radioBtns" onClick={() => { settype('public'); }}>
                                                    <div className={type === 'public' ? "activeRadioBtn" : "radioBtn"}>

                                                    </div>
                                                    <div className="radioText" >
                                                        <div>Public</div>
                                                        <span>Anyone can post a tribute and stories under this memorial</span>
                                                    </div>
                                                </div>
                                                <div className="radioBtns" onClick={() => { settype('private') }}>
                                                    <div className={type === 'private' ? "activeRadioBtn" : "radioBtn"}>

                                                    </div>
                                                    <div className="radioText">
                                                        <div>Private</div>
                                                        <span>Only people with the link can post a tribute and stories under this memorial</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </ShadowCard>





                                        <div className="update_home_btn" >
                                            <PrimaryBtn
                                                txtColor="white" pd="5px" br="7px" w="120px" bg="#FF7900" hoverBG="#FF7900"
                                                // txt={updating ? <ClipLoader color="white" loading={updating} speedMultiplier={1} size={22} /> : status === 'saved' ? "Publish" : "Update"}
                                                txt={updatem ? "Updating..." : 'Update'}
                                                border="#FF7900" fw="400"
                                                // onClick={() => status === 'saved' ? publishMemo() : UpdateMemo()}
                                                onClick={UpdateMemo}
                                            // type="button"
                                            />

                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </div>





            </div>


        </>

    );
}

export default UpdateMemorial;
