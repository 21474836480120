import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'





const getbanneddata = async () => {
    const response = await AuthAxios.get(`/story/banned`);
    const data = response.data.data;
    return data;
  };
  
  export const useGetBannedData = () => {
    const {setbannedusers } = useContext(DashboardContext);
    return useQuery("fetch banned data ", () => getbanneddata(), {
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        // console.log(data, "banned data")
        setbannedusers(data);
      },
    });
  };
  
