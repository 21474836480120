import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import { Dialog, DialogActions, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4, 4, 4),
    borderRadius: '8px',
    outline: 'none', // Remove default outline
  },
  button: {
    width: '100px',
    height: '40px',
    margin: theme.spacing(1),
    borderRadius: '4px',
    '&.yesButton': {
      backgroundColor: 'gray',
      color: 'white',
    },
    '&.noButton': {
      backgroundColor: 'orange',
      color: 'white',
    },
  },
}));


export default function Dropdown({
  editClick,
  deactivateclick,
  deleteClick,
  SuspendClick,
  UpgradeClick,
  UpdateClick,
  deleteuser,
  hideClick,
  propone,
  proptwo,
  suspendprop,
  updatememorial,
  upgrade,
  deactivateuser,
}) {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false); // State for controlling the backdrop

  const open = Boolean(anchorEl);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmAction = () => {
    setOpenModal(false);
    setShowBackdrop(false);
    switch (selectedAction) {
      case 'delete':
        deleteClick();
        break;
      case 'suspend':
        SuspendClick();
        break;
      case 'upgrade':
        UpgradeClick();
        break;
      case 'update':
        UpdateClick();
        break;
      default:
        break;
    }

    setOpenModal(false);
  };

  const handleCancelAction = () => {
    setOpenModal(false);
    setShowBackdrop(false); // Hide backdrop if modal is canceled
  };

  const handleActionClick = (action) => {
    setSelectedAction(action);
    setOpenModal(true);
    setShowBackdrop(true); // Show backdrop when modal opens
  };

  return (
    <div style={{ position: 'relative' }}>
    {showBackdrop && (
        <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, opacity: 0.1  }}>
          {/* You can customize the backdrop here */}
        </Backdrop>
      )}
      <div>
        <button
          style={{
            padding: '4px',
            paddingLeft: '8px',
            backgroundColor: '#FD7902',
            borderRadius: '4px',
          }}
          onClick={handleClick}
        >
          <p style={{ color: '#F5F5F5' }}>
            {' '}
            View <ArrowDropDownIcon style={{ color: '#F5F5F5' }} />
          </p>
        </button>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {deactivateuser ? (
          <MenuItem onClick={() => { handleClose(); deactivateclick() }}>
            {deactivateuser && 'Ban User'}
          </MenuItem>
        ) : (
          <div>
            <MenuItem onClick={() => { handleClose(); editClick() }}>
              {propone ? propone : 'View'}
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); handleActionClick('delete') }}>
              {proptwo ? proptwo : 'Delete'}
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); handleActionClick('suspend') }}>
              {suspendprop && 'Suspend'}
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); handleActionClick('update') }}>
              {updatememorial && 'Update Memorial'}
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); handleActionClick('upgrade') }}>
              {upgrade && 'Upgrade Memorial Plan'}
            </MenuItem>
          </div>
        )}
      </Menu>
      <Dialog open={openModal} onClose={handleCancelAction} className={styles.modal}>
        <div className={styles.paper}>
          <p>{`Do you want to ${selectedAction}?`}</p>
          <DialogActions>
            <Button className={`${styles.button} yesButton`} onClick={handleConfirmAction}>
              Yes
            </Button>
            <Button className={`${styles.button} noButton`} onClick={handleCancelAction}>
              No
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
