import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

import { DashboardContext } from "../Context/DashboardContext";
import AuthAxios from "../utils/AuthAxios";

function Piedata({ data_, name, refetch }) {
  const [pietable, setpietable] = useState();

  // const { refetch } = useGetAnalyticData(priceType);

  const sum = pietable?.reduce((accumulator, object) => {
    return accumulator + object.value;
  }, 0);

  const [selectType, setselecttype] = useState("NGN");

  useEffect(() => {
    // console.log(data_, "dddxxe");
    if (data_ === undefined) {
      AuthAxios.get(`/dashboard/package/${selectType}`)
        .then(function (res) {
          let response = [];
          res?.data?.data?.map((datum) => {
            let key = Object?.keys(datum)[0];
            let value = datum[key];
            response = [
              ...response,
              {
                name: key,
                value: value,
              },
            ];
          });
          setpietable(response);
          // setpackageresponse(response)
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      let response = [];
      data_?.data?.data?.map((datum) => {
        let key = Object?.keys(datum)[0];
        let value = datum[key];
        response = [
          ...response,
          {
            name: key,
            value: value,
          },
        ];
      });
      setpietable(response);
    }
  }, [name]);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100)?.toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="pieandfreemium">

    <div  className="responsivepie ">

        <ResponsiveContainer  height={200}>
            {/* <PieChart width={400} height={400}> */}
            <div className="flex">
            <PieChart width={200} height={200}>
              <Pie
                data={pietable}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {pietable?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS?.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            </div>
          </ResponsiveContainer>


          <div>
            <div className="flex    ">
              <div className="flex  gap-10 flex-wrap  ">
                {pietable?.map((x, i) => {
                  return (
                    <div>
                      <div className="packagename"  >{x?.name}</div>
                      <div className="packagepercent"  >{((x?.value / sum) * 100)?.toFixed(0)}%</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

    </div>


   


    </div>
  );
}

export default Piedata;
