import React, { forwardRef, useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";


import { useTransdelete } from "../../api/useTransdelete";

import { jsPDF } from "jspdf";
import { useFilter } from "../../api/useFilter";
import moment from "moment";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { DashboardContext } from "../../Context/DashboardContext";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import useFreePlan from "../../api/useFreePlan";

import deletesvg from "../../Assets/deletesvg.svg";
import useView from "../../api/useView";

import useStory from "../../api/useStory";
import { useGetStoryAnalytics } from "../../api/useStoryAnalytics";
import Analytics from "../Analytics";
import DropdownStory from "../../Components/DropdownStory";
import useStorydelete from "../../api/useStorydelete";
import useBanStory from "../../api/useBanStrory";
import useTribute from "../../api/useTribute";
import DropdownTribute from "../../Components/DropdownTribute";
import useTributedelete from "../../api/useTributedelete";
import useBanTribute from "../../api/useBanTribute";



function ManageTribute() {
  const defaultMaterialTheme = createTheme();



  const [startDate] = useState(new Date());

  const {
    UpdateMenuClicked,

    MoreInfodata,
    transdetails,
    infodata,
    settransindividual,
    storyanalytics,
  } = useContext(DashboardContext);
  const { refetch, isLoading, data, isSuccess: success } = useFreePlan();
  const { data: tributedata, isLoading: isloadingtribute } = useTribute();

  const {
    refetch: isrefetch,
    isSuccess,
    isLoading: Load,
    data: isdata,
  } = useFilter(moment(startDate)?.format("YYYY-MM-D"));

  const {
    refetch: refetchview,
    isSuccess: isSuccessview,
    isLoading: isloadingview,
    data: dataview,
  } = useView(infodata?.id);

  const {
    refetch: refetchdelete,
    isSuccess: isSuccessdelete,
    isLoading: isloadingdelete,
    data: datadelete,
  } = useView(infodata?.id);



  const {
    refetch: refetchanalytics,
    isSuccess: isSuccessanalytics,
    isLoading: isloadinganalytics,
    data: dataanalytics,
  } = useGetStoryAnalytics();



  const onSuccess = (item) => {
    // refetch()
    //  refetchdeletestory()
    // console.log(dataanalytics, "itemmmm")
    // console.log(datadeletetribute, "datadeletestory")
  };






  const handleDeleteUser = (data, type) => {
    refetchdeletetribute();
    //   console.log(data, "delete user")
  };

  const onError = (error) => { };


  const {
    refetch: refetchdeletetribute,
    isSuccess: isSuccessdeletetribute,
    isLoading: isloadingdeletetribute,
    data: datadeletetribute,
  } = useTributedelete(infodata?.id);


  const {
    refetch: refetchbanstory,
    isSuccess: isSuccessbanstory,
    isLoading: isloadingbanstory,
    data: databanstory,
  } = useBanTribute(infodata?.id);


  //   const onSuccess = (item) => {
  //     refetch();
  //   };



  const handleBanUser = (data, type) => {
    //   refetchview()
    refetchbanstory()
    console.log(data, "ban user");
  };



  const Analyticsdata = [
    {
      value: storyanalytics?.data?.data?.total_stories,
      name: "Total Stories",
    },
    {
      value: storyanalytics?.data?.data?.total_stories_with_images,
      name: "Total stories with images",
    },
    {
      value: storyanalytics?.data?.data?.total_stories_without_images,
      name: "Total stories without images",
    },
  ];





  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };



  const columns = [
    { title: "Added By", field: "added_by" },
    { title: "Tribute Posted", field: "tribute" },
    {
      title: "Date",
      field: "date",

      render: (rowData) => (
        <div>
          <p>{moment(rowData?.date)?.format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Action",
      field: "more",

      render: (rowData) => (
        <div>
          <DropdownTribute
            deleteClick={() => handleDeleteUser(infodata, "Tribute")}
            banClick={() => handleBanUser(infodata, "Tribute")}
          />
        </div>
      ),
    },
  ];



  const handleBulkDelete = (rowData) => {
    const id = rowData.map((x) => x?.id);

    const data = { transaction_ids: id };

    deleteUser(data);
    // console.log(data, "deleted data")
  };

  const { mutate: deleteUser } = useTransdelete(onSuccess, onError);

  //   const HandleFilter = () => {
  //     return (
  //       console.log(moment(startDate)?.format("YYYY-MM-D"), "startdfate"),
  //       console.log(moment(startDate)?.format("YYYY-MM-D")),
  //       console.log(transdetails, "to use"),
  //       isrefetch()
  //     );
  //   };

  //   useEffect(() => {
  //     refetchanalytics();
  //   }, []);



  return (

    <>
      <div>

        {

          //   isloadingbanstory || isloadingtribute  ? (
          //     <Backdrop
          //       sx={{
          //         color: "#fff",
          //         zIndex: (theme) => theme.zIndex.drawer + 2,
          //       }}
          //       open={isloadingtribute}
          //     >
          //       <CircularProgress color="inherit" />
          //     </Backdrop>
          //   ) :



          <body>
            <Navbar />
            <div
              className="relative min-h-screen md:flex"
              data-dev-hint="container"
            >
              <input type="checkbox" id="menu-open" className="hidden" />

              <header
                className="bg-gray-600 text-gray-100 flex justify-between md:hidden"
                data-dev-hint="mobile menu bar"
              >
                <a
                  href="#/"
                  className="block p-4 text-white font-bold whitespace-nowrap truncate"
                >
                  {/* Create Tribute */}
                </a>

                <label
                  for="menu-open"
                  id="mobile-menu-button"
                  className="m-2 p-2 focus:outline-none hover:text-white hover:bg-gray-700 rounded-md"
                >
                  <svg
                    id="menu-open-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    id="menu-close-icon"
                    className="h-6 w-6 transition duration-200 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </label>
              </header>

              <aside
                id="sidebar"
                className=" zindexproperty text-gray-100     space-y-6  px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
                data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation"
              >
                <Sidebar arrowclick="alltributes" Atributes="alltributes" />
              </aside>

              <div className=" bg-black  bgclass "></div>

              <main
                id="content"
                className="flex-1 mt-8  xl:mr-12 md:w-12 sm:mr-0    bg-sidebar   "
              >
                <div className="mx-auto">

                  <div className="pt-7">
                    <div></div>

                    <ThemeProvider theme={defaultMaterialTheme}>
                      <div>


                        <div style={{ maxWidth: "100%" }}>
                          <div
                            style={{
                              marginBottom: "-50px",
                              zIndex: "2000",
                              position: "relative",
                              width: "100%",
                              maxWidth: "300px",
                            }}
                          >
                            <div>
                              <div className="pr-10 pl-10 pt-8  ">
                                <h1 className="analytics">ALL TRIBUTES</h1>
                              </div>
                            </div>
                          </div>

                          {isLoading ? (
                            <p>Loadingdata...</p>
                          ) : (
                            <div className="toptobottom ">
                              {/* {console.log(data, "free dataaaa")} */}

                              <MaterialTable
                                icons={tableIcons}
                                data={tributedata?.map((v) => ({
                                  ...v,
                                  more: "...",
                                }))}
                                onSelectionChange={(rows) => console.log(rows)}
                                columns={columns}
                                options={{
                                  selection: true,
                                  showTitle: false,
                                  showEmptyDataSourceMessage: false,
                                  pageSizeOptions: [20, 50],
                                  paging: true,
                                  emptyRowsWhenPaging: false,
                                  pageSize: 20,

                                  showTextRowsSelected: false,
                                  actionsColumnIndex: -1,
                                }}
                                actions={[
                                  {
                                    icon: () => (
                                      <img src={deletesvg} alt="deleteicon" />
                                    ),

                                    onClick: (event, rowData) => {
                                      handleBulkDelete(rowData);
                                    },
                                  },
                                ]}
                                localization={{
                                  pagination: {
                                    nextAriaLabel: "Next Page",
                                    labelRowsPerPage: " ",
                                  },
                                }}
                                onRowClick={(event, rowData, togglePanel) => {
                                  return (
                                    UpdateMenuClicked("moredetails"),
                                    MoreInfodata(rowData),
                                    // setselectedclicked(rowData?.map((x) => x?.id)),
                                    // console.log(rowData),
                                    // navigate(`/moredetails/${rowData?.id}`),
                                    settransindividual(rowData)
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </ThemeProvider>
                  </div>

                </div>
              </main>
            </div>
          </body>
        }
      </div>

    </>


  );
}

export default ManageTribute;
