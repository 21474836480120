import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'





const getbanneddata = async () => {
    const response = await AuthAxios.get(`/tribute/banned`);
    const data = response.data.data;
    return data;
  };
  
  export const useGetBannedTribute = () => {
    const {bantribute, setBanTribute } = useContext(DashboardContext);
    return useQuery("fetch banned tribute ", () => getbanneddata(), {
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        // console.log(data, "banned data")
        setBanTribute(data);
      },
    });
  };
  
