import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const Viewuser = async (id) => {
  const response = await AuthAxios?.get(`/memorial/view/${id}`)
  const data = response.data.data
  return data
}



export default function useView(id) {

  const { viewresponse, setViewResponse } = useContext(DashboardContext);
  return useQuery('View User', () => Viewuser(id), {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      console.log(data, "memorialdata data")

      setViewResponse(data);



    },
  })
}
