import { useContext } from "react";
import { useQuery } from "react-query";
import { DashboardContext } from "../Context/DashboardContext";
import AuthAxios from "../utils/AuthAxios";


const getPaymentdata = async (currency, date) => {
  const response = await AuthAxios.get(
    `/dashboard/payment/history/${currency}?date=${date}`
  );
  const data = response;
  return data;
};

export const useGetPaymentData = (currency, date) => {
  const {  PaymentData  } = useContext(DashboardContext);

  return useQuery("fetch payment data", () => getPaymentdata(currency, date), {
     refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // console.log(data?.data?.data, "PaymentData")
      PaymentData(data);
    },
  });
};
