import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useGetPackageData } from "../api/usePackage";
import Countries from "../Components/Countries";
import CountrySelect from "../Components/CountrySelect";
import Piedata from "../Components/Piedata";
import { DashboardContext } from "../Context/DashboardContext";
import AuthAxios from "../utils/AuthAxios";
import { Local_storage } from "../utils/LocalStorageConfig";
import { PieChart } from "../Components/PieChart";

function Packages() {
  const [selectType, setselecttype] = useState("NGN");

  // const { packagelast } = useContext(DashboardContext);

  const [packageresponse, setpackageresponse] = useState();

  useEffect(() => {
    AuthAxios.get(`/dashboard/package/${selectType}`)
      .then(function (response) {
        setpackageresponse(response);
        // console.log(response?.data?.data)
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, [selectType]);

  return (
    <div>
      <div>
        <div className="pr-10 pl-10 pt-8 bordertop">
          <div className="">
            <div>
              <h1 className="analytics">PACKAGES</h1>
              <span className="font-inter text-[#666974] text-[15px]  not-italic">
                Users Per Package Type
              </span>
            </div>

            <div>
              <Countries selectType={selectType} setType={setselecttype} />
            </div>

            <div>
              {/* <Piedata name={selectType} data_={packageresponse} /> */}

              <PieChart/>

              {/* <div></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Packages;
