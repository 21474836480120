import React, { useContext } from "react";
import { useGetDashboardData } from "../api/useDashboard";
import { DashboardContext } from "../Context/DashboardContext";
import {BrowserRouter as Router,Routes,Route,Link} from "react-router-dom";
import './Analytics.css'

function Analytics({ data, title, subtitle }) {
  const { } = useGetDashboardData();

  const { analytics } =
    useContext(DashboardContext);

  const Analyticsdata = [
    {
      value: analytics?.data?.data?.total_users,
      name: "Total Users",
    },
    {
      value: analytics?.data?.data?.total_memorials,
      name: "Total Memorials",
    },
    {
      value: analytics?.data?.data?.total_view_count,
      name: "Total View Count",
    },
    {
      value: analytics?.data?.data?.total_tribute,
      name: "Total Tributes",
    },
    {
      value: analytics?.data?.data?.total_story,
      name: "Total Stories",
    },
    {
      value: analytics?.data?.data?.total_images,
      name: "Total Images",
    },
    {
      value: analytics?.data?.data?.sales_count,
      name: "Daily sales count",
    },
    {
      value: analytics?.data?.data?.suspended_memorials,
      name: "Suspended Memorials",
    },
    {
      value: analytics?.data?.data?.banned_users,
      name: "Banned Users",
    },
  ];

  return (
    <div>
      <div className="shadow-bxshadow-container">
        <h1 className="font-inter pr-10 pl-10   not-italic pb-5 boldtitle  ">
          {title}
        </h1>
      </div>

      <div>
        <div className="pr-10 pl-10 pt-8  ">
          <h1 className="analytics">{subtitle}</h1>
        </div>
      </div>

      <div className="grid lg:grid-cols-3  md:grid-cols-2 sm:grid-cols-1  gap-8 pr-10 pl-10 pt-8 pb-10  ">
        {/* {data?.map((x, i) => {
          return ( */}
        <div className="h-40 lime boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">
          <div>
            <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
              <span>{analytics?.data?.data?.total_users}</span>
            </div>
            <div>
              <span className="text-[#716E6E]"><Link to="/users"> Total Users</Link></span>
            </div>
          </div>



        </div>

        <div className="h-40 two boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">


          <div>
            <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
              <span>{analytics?.data?.data?.total_memorials}</span>
            </div>
            <div>
              <span className="text-[#716E6E]">Total Memorials</span>
            </div>
          </div>


        </div>

        <div className="h-40 four boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">


          <div>
            <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
              <span>{analytics?.data?.data?.total_view_count}</span>
            </div>
            <div>
              <span className="text-[#716E6E]">Total View Count</span>
            </div>
          </div>


        </div>
        
        <div className="h-40 five boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">


          <div>
            <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
              <span>{analytics?.data?.data?.total_tribute}</span>
            </div>
            <div>
              <span className="text-[#716E6E]"><Link to ='/managetribute'>Total Tributes</Link></span>
            </div>
          </div>


        </div>

        <div className="h-40 six boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">


<div>
  <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
    <span>{analytics?.data?.data?.total_story}</span>
  </div>
  <div>
    <span className="text-[#716E6E]"><Link to ='/managestories'>Total Stories</Link></span>
  </div>
</div>


</div>
<div className="h-40 seven boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">


<div>
  <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
    <span>{analytics?.data?.data?.total_images}</span>
  </div>
  <div>
    <span className="text-[#716E6E]"><Link to = '/manageimages'>Total Images </Link></span>
  </div>
</div>


</div>

        <div className="h-40 three boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">


          <div>
            <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
              <span>{analytics?.data?.data?.sales_count}</span>
            </div>
            <div>
              <span className="text-[#716E6E]">Daily sales count</span>
            </div>
          </div>


        </div>


        <div className="h-40 eight boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">


          <div>
            <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
              <span>{analytics?.data?.data?.suspended_memorials}</span>
            </div>
            <div>
              <span className="text-[#716E6E]">Suspended Memorials</span>
            </div>
          </div>


        </div>


        <div className="h-40 nine boderstyle bg-[#F7F7F7] flex flex-col justify-center items-center text-center">


          <div>
            <div className="   mb-8 font-inter text-[#716E6E] text-[35px] not-italic  ">
              <span>{analytics?.data?.data?.banned_users}</span>
            </div>
            <div>
              <span className="text-[#716E6E]"><Link to ='/bannedusers'>Banned Users</Link></span>
            </div>
          </div>


        </div>



        {/* );
        })} */}
      </div>
    </div>
  );
}

export default Analytics;
