import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchIndividualId = async (id) => {
    const response = await AuthAxios?.get(`/transaction/details/${id}`)
    const data = response.data.data
    return data
  }



export default function useTransIndividual(id) {

    const { setSingleuser, Singlefiltered} = useContext(DashboardContext);
      return useQuery('fetch ngn plan', ()=>fetchIndividualId(id), {
        refetchOnWindowFocus: false ,
        onSuccess: (data) => {
            // console.log(data, "individual data")
           
            setSingleuser(data);
           
            Singlefiltered(data)
            
          },
      })
}
