import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchYearlyPlan = async (id) => {
    const response = await AuthAxios?.get(`/memorial/${2}`)
    const data = response.data.data
    return data
  }



export default function useYearlyPlan(id) {

    const { setfreeplan} = useContext(DashboardContext);
      return useQuery('fetch yearly plan', ()=>fetchYearlyPlan(id), {
        refetchOnWindowFocus: false ,
        onSuccess: (data) => {
            //  console.log(data, "yearly plandata")
           
            setfreeplan(data);
        
           
            
          },
      })
}
