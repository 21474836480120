import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchimages = async (id) => {
    const response = await AuthAxios?.get(`/image`)
    const data = response.data.data
    return data
  }



export default function useImages() {

    const {tributedata, settributedata} = useContext(DashboardContext);

      return useQuery('fetch tribute', ()=>fetchimages(), {
        refetchOnWindowFocus: false ,
        // enabled: false,


        onSuccess: (data) => {
            //  console.log(data, "/images")
           
            settributedata(data);
  
          },
      })
}
