import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { DashboardContext } from '../Context/DashboardContext'
import AuthAxios from '../utils/AuthAxios'

const fetchstory = async (id) => {
    const response = await AuthAxios?.get(`/story`)
    const data = response.data.data
    return data
  }



export default function useStory() {

    const {storiesdata, setstoriesdata} = useContext(DashboardContext);

      return useQuery('fetch story', ()=>fetchstory(), {
        refetchOnWindowFocus: false ,
        onSuccess: (data) => {
            // console.log(data, "/story")
           
            setstoriesdata(data);
        
           
            
          },
      })
}
