/* eslint-disable no-implied-eval */
import React, { useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Local_storage } from "../../utils/LocalStorageConfig";
import { useState } from "react";
import DialogClickout from "../../Components/DialogClickout";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function AllPlans() {
    let { id } = useParams();
    let navigate = useNavigate();

    const [alldata, setAlldata] = useState();
    const [isLoading, SetisLoading] = useState(false);
    const [active, setActive] = useState(null)
    const [deactivate, setdeactivate] = useState(false);
    const [upgradeplan, setupgradeplan] = useState(false);
    const [upgrades, setupgrades] = useState(false)
    const [slug, setSlug] = useState()
    const [freemium, setFreemium] = useState()
    const [description, setDescription] = useState()
    const [price, setPrice] = useState()
    const [duration, setDuration] = useState()
    const [interval, setInterval] = useState()
    const [currency, setCurrency] = useState("NGN")
    const [plan, setPlan] = useState()
    const [isupdate, setisupdate] = useState(false)
    const [isUpdatepopup, setisUpdatepopup] = useState(false)
    const [isUpdateerror, setisUpdateerror] = useState(false)
    const [updatefeature, setUpdatefeature] = useState(false)
    const [featuresuccess, setfeaturesuccess] = useState(false)


    const schema = yup.object().shape({
        first_name: yup
            .string()
            .required("first_name is required")
            .min(3, "Can't be lesser than 3 digits")
            .max(75, "Can't exceed 75 digits")
            .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
        phone: yup.string().required("Phone number is required"),
        last_name: yup
            .string()
            .required("last_name is required")
            .min(3, "Can't be lesser than 3 digits")
            .max(75, "Can't exceed 75 digits")
            .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
        email: yup
            .string()
            .matches(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                "Valid email is required"
            )
            .required("Valid email is required"),
        password: yup.string().required("Password is required"),
        password_confirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "Password mismatch!")
            .required("Required"),
    });


    const {
        control,
        getValues,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
    });

    const handlePlansPage = (data) => {
        console.log(data, "datatatta");

        // navigate(`/planspage/${data}`, {
        //     state: alldata,
        // });
    };

    useEffect(() => {
        SetisLoading(true);

        var myHeaders = new Headers();
        myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${Local_storage().get("_utk")}`);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,

            redirect: "follow",
        };

        fetch("https://api.createtribute.com/admin/plan", requestOptions)
            .then((response) => response.json())
            .then((res) => {
                SetisLoading(false);
                console.log(res, "res");
                console.log(res?.data?.map((x) => x?.features));
                setAlldata(res?.data);
            })
            .catch((err) => {
                console.log("error", err);
            });
    }, []);
    const [planid, setplanId] = useState("")
    const [value, setValue] = useState()
    const [feature, setFeature] = useState()




    const [feature_id, setFeature_id] = useState(false)




    const handlePlan = (e) => {
        setPlan(e.target.value)
    }


    const handleChange = (e) => {
        setplanId(e.target.value)
    }

    const handleFeatureId = (e) => {
        setFeature(e.target.value)
    }


    const handleValue = (e) => {
        setValue(e?.target?.value)
    }


    const handleSlug = (e) => {
        setSlug(e.target.value)
    }


    const handleFreemium = (e) => {
        setFreemium(e.target.value)
    }

    const handleDescription = (e) => {
        setDescription(e.target.value)
    }

    const handlePrice = (e) => {
        setPrice(e.target.value)
    }

    const handleInterval = (e) => {
        setInterval(e.target.value)
    }

    const handleDuration = (e) => {
        setDuration(e.target.value)
    }

    const handleCurency = (e) => {
        setCurrency(e.target.value)
    }





    const SubmitPlan = (e) => {
        e.preventDefault()
        setdeactivate(false)
        console.log(planid, 'planid')

        var myHeaders = new Headers();
        myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${Local_storage()?.get('_utk')}`);


        var formdata = new FormData();
        formdata.append("plan_id", Number(planid));

        // var requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: formdata,
        //     redirect: 'follow'
        // };

        // fetch(`https://api.createtribute.com/admin/memorial/plan/upgrade/${infodata?.uuid}`, requestOptions)
        //     .then(response => response.json())
        //     .then((res) => {
        //         console.log(res)
        //         setplanId("")
        //     })
        //     .catch((err) => {
        //         console.log(err, "err")
        //         console.log(err.response, 'responseee')
        //     });





        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            // body: formdata,
            redirect: 'follow'
        };

        fetch(`https://api.createtribute.com/admin/plan/deactivate/${planid}`, requestOptions)
            .then(response => response.json())
            .then((res) => {
                console.log(res)
                setplanId("")
                setupgrades(true)

                // Swal.fire({
                //     icon: "success",
                //     iconColor: "white",
                //     text: `Success`,
                //     confirmButtonColor: "#FF7900",
                //     // timer: 3000,
                //     showConfirmButton: true
                // }).then(() => {
                //     navigate('/plans')
                // });

            })
            .catch((err) => {
                console.log(err, "err")
                console.log(err.response, 'responseee')
                setplanId("")
                // Swal.fire({
                //     icon: "error",
                //     iconColor: "white",
                //     text: `An Error Occured`,
                //     confirmButtonColor: "#FF7900",
                //     // timer: 3000,
                //     showConfirmButton: true
                // }).then(() => {
                //     navigate('/plans')
                // });
            });


    }


    const SubmitFeaturePlan = (e) => {
        e.preventDefault()

        console.log(value, feature, 'feature_id, value')



        var myHeaders = new Headers();
        myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${Local_storage()?.get('_utk')}`);

        var formdata = new FormData();
        formdata.append("value", value);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`https://api.createtribute.com/admin/plan/feature/update/${feature}`, requestOptions)
            .then(response => response.json())
            .then((res) => {
                console.log(res, 'resssss')
                setfeaturesuccess(true)
                setValue()
                setFeature()
                setFeature_id(false)

            })
            .catch(error => console.log('error', error));



    }






    const handleUpgradePlan = (e) => {
        e.preventDefault()
        setisupdate(true)

        // console.log(plan, 'PlanId', slug, "slug", freemium, "freemium", description, 'description', price, 'price', duration, 'duration', interval, 'interval', currency, 'currency')
        // console.log(currency, 'currencyyyyyyyy')



        var myHeaders = new Headers();
        myHeaders.append("Api-Token", `${process.env.REACT_APP_API_TOKEN}`);
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${Local_storage()?.get('_utk')}`);

        var formdata = new FormData();
        formdata.append("slug", slug);
        formdata.append("name", freemium);
        formdata.append("description", description);
        formdata.append("price", price);
        formdata.append("currency", currency);
        formdata.append("interval", interval);
        formdata.append("duration", duration);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`https://api.createtribute.com/admin/plan/update/${plan}`, requestOptions)
            .then(response => response.json())
            .then((res) => {

                setPlan('')
                setSlug('')
                setFreemium('')
                setDescription('')
                setPrice('')
                setDuration('')
                setInterval('')
                setCurrency('')
                setisupdate(false)
                setupgradeplan(false)

                console.log(res, 'res')

                if (res?.errors?.currency?.length >= 1) {
                    setisUpdatepopup(false)
                    setisupdate(false)
                    SetisLoading(false);
                    setisUpdateerror(true)
                    setupgradeplan(false)
                } else {
                    setisUpdatepopup(true)
                    setupgradeplan(false)
                    setisupdate(false)
                    SetisLoading(false);
                }

            })
            .catch((err) => {
                console.log(err, 'err')
                setisupdate(false)
                setisUpdatepopup(false)
                setPlan('')
                setSlug('')
                setFreemium('')
                setDescription('')
                setPrice('')
                setDuration('')
                setInterval('')
                setCurrency('')
            });




    }


    return (
        <>
            {isLoading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <body>
                    <Navbar />
                    <div>
                        <div
                            className="relative min-h-screen md:flex"
                            data-dev-hint="container"
                        >
                            <input type="checkbox" id="menu-open" className="hidden" />

                            <header
                                className="bg-gray-600 text-gray-100 flex justify-between md:hidden"
                                data-dev-hint="mobile menu bar"
                            >
                                {/* <a
                                    href="#/"
                                    className="block p-4 text-white font-bold whitespace-nowrap truncate"
                                ></a> */}

                                <label
                                    for="menu-open"
                                    id="mobile-menu-button"
                                    className="m-2 p-2 focus:outline-none hover:text-white hover:bg-gray-700 rounded-md"
                                >
                                    <svg
                                        id="menu-open-icon"
                                        className="h-6 w-6 transition duration-200 ease-in-out"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                    <svg
                                        id="menu-close-icon"
                                        className="h-6 w-6 transition duration-200 ease-in-out"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </label>
                            </header>

                            <aside
                                id="sidebar"
                                className=" zindexproperty text-gray-100     space-y-6  px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto"
                                data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation"
                            >
                                <Sidebar Mplans="allplans" arrowclick="allplans" />
                            </aside>

                            <div className=" bg-black  bgclass "></div>

                            <main
                                id="content"
                                className="flex-1 mt-8  xl:mr-12 md:w-12 sm:mr-0    bg-sidebar   "
                            >
                                <div className=" mx-auto">
                                    {isLoading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",
                                                zIndex: (theme) => theme.zIndex.drawer + 2,
                                            }}
                                            open={isLoading}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    ) : (
                                        <div>
                                            <div></div>

                                            {
                                                !isLoading &&
                                                <div className="pt-7">
                                                    <div className="bg-sidebar flex-auto   ">
                                                        <div className=" shadow-bxshadow-container   ">
                                                            <h1 className="font-inter pr-10 pl-10 text-xs  not-italic pb-5  ">
                                                                Manage Plans/{" "}
                                                                <span className=" text-[#FF7900] ">
                                                                    Available Plans
                                                                </span>
                                                            </h1>
                                                        </div>

                                                        <div>
                                                            <div className="pr-10 pl-10 pt-8  ">
                                                                <h1 className="analytics">ALL PLANS</h1>

                                                            </div>
                                                            <div className="pr-10 pl-10 pt-8  ">
                                                                <button className="top_btn" onClick={() => setdeactivate(true)} >Deactivate Plan</button> &nbsp; &nbsp;
                                                                <button className="top_btn" onClick={() => setupgradeplan(true)} >Update Plan</button> &nbsp; &nbsp;
                                                                <button className="top_btn" onClick={() => setFeature_id(true)} >Update Plan Feature Plan</button>
                                                            </div>
                                                        </div>

                                                        {alldata?.map((x, i) =>
                                                            // return (
                                                            <div
                                                                className="
                                                          pt-8 sm:pr-0 md:pr-0
                                                               "
                                                            >
                                                                <div
                                                                    className="
                                                        pr-10 md:pr-0 sm:pr-0 pl-10 pt-8  bg-[#FCFCFC] h-fit rounded borderbtm
                                                            "
                                                                >
                                                                    <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                                                        Currency: {x?.currency} &nbsp; &nbsp; &nbsp;
                                                                        duration: {x?.duration}
                                                                        &nbsp; &nbsp; &nbsp; Interval: {
                                                                            x?.interval
                                                                        }{" "}
                                                                        &nbsp; &nbsp; &nbsp; Plan Id: {x?.id}
                                                                    </p>

                                                                    <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                                                        Name: {x?.name} &nbsp; &nbsp; &nbsp; Slug:{" "}
                                                                        {x?.slug}
                                                                        &nbsp; &nbsp; &nbsp; Price: {x?.price}{" "}
                                                                        &nbsp; &nbsp; &nbsp; Status: {x?.status}
                                                                    </p>

                                                                    <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                                                        description: {x?.description}
                                                                    </p>

                                                                    <h1 className="features_tag" >Features</h1>

                                                                    {
                                                                        x?.features?.map((x, i) => {
                                                                            return (
                                                                                <div>


                                                                                    <p className=" font-inter pb-4 text-[20px]  not-italic text-[#333333]  ">
                                                                                        Name: {x?.name}     &nbsp; &nbsp; &nbsp;  Description: {x?.description}  &nbsp; &nbsp; &nbsp; Feature Id: {x?.id}  &nbsp; &nbsp; &nbsp; Value: {x?.value}
                                                                                    </p>
                                                                                </div>

                                                                            )
                                                                        })
                                                                    }

                                                                </div>

                                                                <div
                                                                    className="
                                pr-10 pl-10 pt-8 md:pr-0 sm:pr-0  bg-[#FCFCFC] h-fit rounded
                                       "
                                                                ></div>
                                                            </div>
                                                            // );
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>

                                <DialogClickout
                                    open={deactivate}

                                >


                                    <div className='Upgrade_enable' >

                                        <div>
                                            <div style={{
                                                display: 'flex', justifyContent: 'space-between', marginBottom: '20px'
                                            }} >
                                                <div><h1 className='aud_enb' >Deactivate Plan</h1></div>
                                                <div>   <span className="close_btn" onClick={() => setdeactivate(false)} >Close</span></div>
                                            </div>


                                        </div>

                                        <div>


                                            <form onSubmit={SubmitPlan} >

                                                <div className="form_content">
                                                    <div className="form_input" >
                                                        <input required value={planid} type="number" onChange={handleChange} placeholder="planid" />
                                                    </div>


                                                    <div className="form_btn">
                                                        <input type="submit" value="Submit" />
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </DialogClickout>




                                <DialogClickout
                                    open={feature_id}

                                >


                                    <div className='Upgrade_enable' >

                                        <div>
                                            <div style={{
                                                display: 'flex', justifyContent: 'space-between', marginBottom: '20px'
                                            }} >
                                                <div><h1 className='aud_enb' >Update Plan Feature</h1></div> &nbsp; &nbsp; &nbsp;
                                                <div>   <span className="close_btn" onClick={() => setFeature_id(false)} >Close</span></div>
                                            </div>


                                        </div>

                                        <div>


                                            <form onSubmit={SubmitFeaturePlan} >

                                                <div className="form_content">
                                                    <div className="form_input" >
                                                        <input required value={feature} type="number" onChange={handleFeatureId} placeholder="featureid" />
                                                    </div>

                                                    <div className="form_input" >
                                                        <input required value={value} type="number" onChange={handleValue} placeholder="value" />
                                                    </div>


                                                    <div className="form_btn">
                                                        <input type="submit" value="Submit" />
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </DialogClickout>















                                <DialogClickout
                                    open={upgrades}

                                >


                                    <div className='Upgrade_enable' >
                                        <div>
                                            <h1 className='aud_enb' >Deactivate Plan Successfully</h1>


                                        </div>

                                        <div>
                                            {/* <button className='aud_btn' onClick={SubmitPlan} >Ok</button> */}

                                            <span onClick={() => setupgrades(false)} >OK</span>

                                        </div>
                                    </div>

                                </DialogClickout>



                                <DialogClickout
                                    open={upgradeplan}

                                >


                                    <div className='Upgrade_enable' >
                                        <div>
                                            <div style={{
                                                display: 'flex', justifyContent: 'space-between', marginBottom: '20px'
                                            }} >
                                                <div><h1 className='aud_enb' >Update Plan</h1></div>
                                                <div>   <span className="close_btn" onClick={() => setupgradeplan(false)} >Close</span></div>
                                            </div>

                                            {/* <span onClick={() => setupgradeplan(false)} >Close</span> */}
                                        </div>

                                        <div>
                                            {/* <button className='aud_btn' onClick={SubmitPlan} >Ok</button> */}

                                            <form onSubmit={handleUpgradePlan} >

                                                <div className="form_content">

                                                    <label htmlFor="">Plan Id</label>
                                                    <div className="form_input" >
                                                        <input required value={plan} type="text" placeholder="plan Id Ex. 1" onChange={handlePlan} />
                                                    </div>

                                                    <label htmlFor="">Slug. e.g free</label>
                                                    <div className="form_input" >
                                                        <input required value={slug} type="text" placeholder="slug. e.g free" onChange={handleSlug} />
                                                    </div>

                                                    <label htmlFor="">Name. e.g freemium</label>

                                                    <div className="form_input" >
                                                        <input required value={freemium} type="text" placeholder="name. e.g freemium" onChange={handleFreemium} />
                                                    </div>

                                                    <label htmlFor="">Description</label>
                                                    <div className="form_input" >
                                                        <textarea required value={description} type="text" placeholder="description" onChange={handleDescription} />
                                                    </div>
                                                    <label htmlFor="">Price</label>
                                                    <div className="form_input" >
                                                        <input required value={price} type="number" placeholder="price" onChange={handlePrice} />
                                                    </div>

                                                    <label htmlFor="">Duration: e.g 1 (duartion is in months)</label>
                                                    <div className="form_input" >

                                                        <input required value={duration} type="number" onChange={handleDuration} placeholder="duration: e.g 1 (duartion is in months)" />
                                                    </div>

                                                    <label htmlFor="">Interval</label>
                                                    <div className="form_btn">
                                                        {/* <Controller
                                                            name="interval"
                                                            defaultValue=""
                                                            control={control}
                                                            render={({ field }) => ( */}
                                                        <div>
                                                            <div className="parentdiv">

                                                                <select
                                                                    // {...field}
                                                                    className="input_name"

                                                                    onChange={handleInterval}
                                                                    value={interval}

                                                                >
                                                                    <option value="daily">Daily</option>
                                                                    <option value="weekly">Weekly</option>
                                                                    <option value="monthly">Monthly</option>
                                                                    <option value="quarterly">Quarterly</option>
                                                                    <option value="semianually">Semianually</option>
                                                                    <option value="anually">Anually</option>
                                                                    <option value="biennially">Biennially</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* )}
                                                        /> */}
                                                    </div>

                                                    <label htmlFor="">Currency</label>
                                                    <div className="form_btn">

                                                        <div>
                                                            <div className="parentdiv">

                                                                <select

                                                                    className="input_name"
                                                                    onChange={(e) => setCurrency(e.target.value)}
                                                                    value={currency}


                                                                >

                                                                    <option value="AUD">AUD</option>
                                                                    <option value="USD">USD</option>
                                                                    <option value="NGN">NGN</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>



                                                    <div className="form_btn">
                                                        <input type="submit" value={isupdate ? 'Loading...' : 'Submit'} />
                                                    </div>

                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </DialogClickout>




                                <DialogClickout
                                    open={isUpdatepopup}

                                >


                                    <div className='Upgrade_enable' >
                                        <div>
                                            <h1 className='aud_enb' >Updated Successfully</h1>


                                        </div>

                                        <div>
                                            {/* <button className='aud_btn' onClick={SubmitPlan} >Ok</button> */}

                                            <span onClick={() => setisUpdatepopup(false)} >OK</span>

                                        </div>
                                    </div>

                                </DialogClickout>



                                <DialogClickout
                                    open={isUpdateerror}

                                >


                                    <div className='Upgrade_enable' >
                                        <div>
                                            <h1 className='aud_enb' >Error Occured</h1>


                                        </div>

                                        <div>
                                            {/* <button className='aud_btn' onClick={SubmitPlan} >Ok</button> */}

                                            <span onClick={() => setisUpdateerror(false)} >OK</span>

                                        </div>
                                    </div>

                                </DialogClickout>





                                <DialogClickout
                                    open={featuresuccess}

                                >


                                    <div className='Upgrade_enable' >
                                        <div>
                                            <h1 className='aud_enb' >Success</h1>


                                        </div>

                                        <div>
                                            {/* <button className='aud_btn' onClick={SubmitPlan} >Ok</button> */}

                                            <span onClick={() => setfeaturesuccess(false)} >OK</span>

                                        </div>
                                    </div>

                                </DialogClickout>



                            </main>
                        </div>
                    </div>
                </body>
            )}
        </>
    );
}

export default AllPlans;
